import { FC, useState, useEffect, useMemo } from "react";
import { Button, Segmented, Spin, Switch, Table, Typography } from "antd";

import dayjs from "dayjs";
import "./Balances.css";
import { toCurrencyNumber, openDoc, TCustomRouteComponentProps, openQueryWithTimestamp, openDocWithTimestamp } from "utils";
import { useParams } from "react-router";
import { Link } from "react-router-dom";
import { Firebase } from "services";

import db from "services/firestore";
import { IEntity, ILedgerTransactionDoc } from "types";
import DocViewer from "pages/Overview/components/DocViewer";
import WritableRecords from "./components/WritableRecords";
import { ICurrencyWallet, IWalletTransaction } from "./interfaces";
import { getLedgerRecord, getLedgerRecordsFromSourceId } from "services/firebase";

const subscribeToEntity = (entityId, callback) => {
  try {
    const unsubscribe = db
      .collection("entities")
      .doc(entityId)
      .onSnapshot((doc) => callback(openDoc(doc)));

    return unsubscribe;
  } catch (error) {
    console.log(error);
    return undefined;
  }
};

const { Title } = Typography;

const Balances: FC<TCustomRouteComponentProps> = ({ allowWrite }) => {
  const [entity, setEntity] = useState(null as IEntity);
  const [wallet, setWallet] = useState(null as ICurrencyWallet);
  const [docToView, setDocToView] = useState(null);
  const [writableRecordsToView, setWritableRecordsToView] = useState(null);

  const [currency, setCurrency] = useState<string>();

  const [expandedRowData, setExpandedRowData] = useState({});
  const [onTheFly, setOnTheFly] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const handleExpand = async (expanded, record: { key: string } & IWalletTransaction) => {
    if (expanded && !expandedRowData[record.key]) {
      try {
        // Fetch the data for the expanded row
        const sourceId = record.sourceId;
        const numberOfDashesInSourceId = (sourceId.match(/-/g) || []).length;
        const sourceIdIsDocId = numberOfDashesInSourceId >= 2;
        const data = sourceIdIsDocId ? await getLedgerRecordsFromSourceId({ sourceId, entityId }) 
        : await getLedgerRecord({ ledgerTransactionId: record.ledgerTransactionId, entityId }).then(data => data ? [data] : []);
        // Update the state with the fetched data
        setExpandedRowData((prev) => ({
          ...prev,
          [record.key]: data,
        }));
      } catch (error) {
        console.error("Error fetching nested data:", error);
      }
    }
  };

  const currenciesForDropdown = useMemo(
    () => Object.keys(wallet || {}),
    [wallet]
  );

  // @ts-ignore
  const { entity_id: entityId } = useParams<{ entity_id: string }>();

  const getTransactionColumns = (setWritableRecordsToView) => {
    const transactionColumns = [
      {
        title: "Settled On",
        dataIndex: "settledOn",
        key: "settledOn",
        render: (date) => dayjs(date).format("DD MMM YYYY HH:mm:ss"),
      },
      {
        title: "Transaction Date",
        dataIndex: "transactionTimestamp",
        key: "transactionTimestamp",
        render: (date) => dayjs(date).format("DD MMM YYYY HH:mm:ss"),
      },
      {
        title: "Reference",
        dataIndex: "reference",
        key: "reference",
      },
      {
        title: "Type",
        dataIndex: "type",
        key: "type",
      },
      {
        title: "Description",
        dataIndex: "description",
        key: "description",
      },
      {
        title: "Credit",
        dataIndex: "amountCredit",
        key: "amountCredit",
        render: (value) => {
          return (
            <div className="currencyAmount">
              {value ? toCurrencyNumber(value) : ""}
            </div>
          );
        },
      },
      {
        title: "Debit",
        dataIndex: "amountDebit",
        key: "amountDebit",
        render: (value) => {
          return (
            <div className="currencyAmount">
              {value ? toCurrencyNumber(value) : ""}
            </div>
          );
        },
      },
      {
        title: "Balance",
        dataIndex: "balance",
        key: "balance",
        render: (value, record) => {
          return <span>
          <Link to="#" onClick={() => setDocToView(record)}>
            <div className="currencyAmount">{toCurrencyNumber(value)}</div>
          </Link>
        </span>;
        },
      },
      {
        title: "Source ID",
        dataIndex: "sourceId",
        key: "sourceId",
        render: (value) => {
          return (
            <span>
              <Link to={"/app/transfer/" + value}>{value}</Link>
            </span>
          );
        },
      },
      {
        title: "Write-to-source status",
        dataIndex: "sourceId",
        key: "sourceId",
        render: (value, record: IWalletTransaction) => {
          const writableResources= record.writableResources || [];
          const noRecords = writableResources.length === 0;
          const recordsButNoneAttempted = writableResources.length > 0 && writableResources.every(writable => !writable.status);
          const none = writableResources.every(writable => writable.status && writable.status !== 'success')
          const all = writableResources.every(writable => writable.status === 'success');
          const some = !none && !all;
          let writeStatusText = 'Unknown';
          if (noRecords) {
            writeStatusText = 'Nothing to write';
          } else if (recordsButNoneAttempted) {
            writeStatusText = 'Not attempted';
          } else if (none) {
            writeStatusText = 'All failed';
          } else if (all) {
            writeStatusText = 'All successful';
          } else if (some) {
            writeStatusText = 'Partial success';
          }
          return (
            <Link to="#" onClick={() => setWritableRecordsToView(writableResources)}>
              {writeStatusText}
            </Link>
          );
        },
      },
    ];

    return transactionColumns;
  }

  const getLedgerTransactionColumns = (setDocToView) => {
    const LedgerTransactionColumns = [
      {
        title: "Created",
        dataIndex: "_created",
        key: "_created",
        render: (date) => dayjs(date).format("DD MMM YYYY HH:mm:ss"),
      },
      {
        title: "Postings",
        dataIndex: "postings",
        key: "postings",
        render: (text, record: ILedgerTransactionDoc) => {
          return (
            <>
              {record.postings?.map((item) => {
                return (
                  <div className="currencyAmount">
                    {`${item.from}->${item.to}`} &nbsp;
                    <b>{item.currency}</b> &nbsp;
                    {toCurrencyNumber(item.amount)}
                  </div>
                );
              })}
            </>
          );
        },
      },
      {
        title: "Status",
        dataIndex: "status",
        key: "status",
      },
      {
        title: "Description",
        dataIndex: "description",
        key: "description",
      },
      {
        title: "Source ID",
        dataIndex: "sourceId",
        key: "sourceId",
        render: (text, record: ILedgerTransactionDoc) => {
          const sourceText = `${record.source} ${record.sourceEvent}`;
          return (
            <span>
              {sourceText}
            </span>
          );
        },
      },
      {
        title: "ID",
        dataIndex: "id",
        key: "id",
        render: (text, record) => {
          return (
            <span>
              <Link to="#" onClick={() => setDocToView(record)}>
                {text}
              </Link>
            </span>
          );
        },
      },
    ];

    return LedgerTransactionColumns;
  };

  useEffect(() => {
    if (entityId) {
      setIsLoading(true);
      Firebase.getBalancesAndTransactions(entityId, onTheFly).then((data) =>
        {
          console.log('data', data);
          setWallet(data)
          if (Object.keys(data)[0]) {
            console.log('setting currency to ', Object.keys(data)[0]);
            setCurrency(Object.keys(data)[0]);
          }
        setIsLoading(false);
        }
      );
    }
  }, [entityId, onTheFly]);

  useEffect(() => {
    if (wallet && Object.keys(wallet)[0]) {
      console.log('setting currency from wallet to ', Object.keys(wallet)[0]);
      setCurrency(Object.keys(wallet)[0]);
    }
  }, [wallet]);

  useEffect(() => {
    if (entityId && !entity) {
      const unsubscribe = subscribeToEntity(entityId, (data) =>
        setEntity(data)
      );

      return () => {
        if (unsubscribe) {
          unsubscribe();
        }
      };
    }
  }, [entityId, entity]);

  const onRegenerateWalletTransactionsClick = async (entityId) => {
      setIsLoading(true);
      Firebase.regenerateWallet(entityId).then((results) => {
        setIsLoading(false);
        if (results.success) {
          alert('Wallet transactions regenerated for ' + entityId);
        } else {
          alert(`Failed to regenerate wallet transactions for ${entityId}: ${results.message}`);
        }
    })
  }

  return (
    <div>
      <>
        <DocViewer
          doc={docToView}
          isVisible={!!docToView}
          onClose={() => setDocToView(null)}
        />
        <WritableRecords
          records={writableRecordsToView}
          isVisible={!!writableRecordsToView}
          onClose={() => setWritableRecordsToView(null)}
        />
      </>
      <>
        <Title>Wallet: Balances and Transactions
        <span
          style={{ float: "right" }}
        >
          <span style={{fontSize: "18px"}}>On-the-fly:</span> 
          <Switch style={{ marginLeft: 10 }} checked={onTheFly} onChange={(e) => setOnTheFly(e)} />
          <Button
            style={{ marginLeft: 20 }}
            // disabled={isLoading}
            onClick={() => onRegenerateWalletTransactionsClick(entityId)}
          >
            Regenerate Wallet Transactions
          </Button>
          
        </span>
        </Title>
        <p>
          {entity && (
            <>
              For{" "}
              <Link to={"/app/entity-detail/" + entity.id}>{entity.name}</Link>
            </>
          )}
        </p>

        {!wallet && isLoading && <Spin style={{ marginBottom: "16px" }} />}
        {wallet && (
          <>
            <Segmented
              onChange={(value) => setCurrency(value as string)}
              options=
                {currenciesForDropdown.map((currency) => (
                  {
                    label: (
                      <div
                        style={{
                          padding: 4,
                          fontWeight: "bold",
                          minWidth: 100,
                        }}
                      >
                        <div style={{fontSize: "larger"}}>{currency}</div>
                        <div>{toCurrencyNumber(wallet[currency].balance)}</div>
                      </div>
                    ),
                    value: currency,
                  }
                ))}
            />
            {isLoading && <Spin style={{ marginLeft: "16px", paddingTop: "20px" }} />}

            <br />
            <br />

            {entityId && wallet && currency && wallet[currency] &&wallet[currency].transactions && (
              <Table
                title={() => <Title level={2}>Balance Transactions</Title>}
                columns={getTransactionColumns(setWritableRecordsToView)}
                dataSource={wallet[currency].transactions.map(item => ({
                  ...item,
                  key: item.ledgerTransactionId,
                }))}
                expandable={{
                  onExpand: handleExpand, // Called when a row is expanded/collapsed
                  expandedRowRender: (record: { key: string } & IWalletTransaction) => (
                    <Table
                      columns={getLedgerTransactionColumns(setDocToView)}
                      dataSource={expandedRowData[record.key] || []} // Use state to provide data for the nested table
                      pagination={false} // Optional: Turn off pagination for nested tables
                    />
                  ),
                }}
              />
            )}
          </>
        )}
      </>
    </div>
  );
};

export default Balances;
