import { FC, useState, useEffect } from "react";
import { Button, Table, Typography } from "antd";
import dayjs from "dayjs";
import { toCurrencyNumber, openQueryWithTimestamp } from "utils";
import { Link } from "react-router-dom";
import db from "services/firestore";
import { IInternalTransfer } from "types/transfers";
const { Title } = Typography;

const subscribeToInternalTransfersFromEntityId = (entityId, callback) => {
  try {
    const unsubscribe = db
      .collection("internalTransfers")
      .where("fromEntityId", "==", entityId)
      .onSnapshot((query) =>
        callback(openQueryWithTimestamp(query, ["_created"]))
      );

    return unsubscribe;
  } catch (error) {
    console.log(error);
  }
};

const subscribeToInternalTransfersToEntityId = (entityId, callback) => {
  try {
    const unsubscribe = db
      .collection("internalTransfers")
      .where("toEntityId", "==", entityId)
      .onSnapshot((query) =>
        callback(openQueryWithTimestamp(query, ["_created"]))
      );

    return unsubscribe;
  } catch (error) {
    console.log(error);
  }
};

const internalTransfersColumns = [
  {
    title: "Created",
    dataIndex: "_created",
    key: "_created",
    render: (date) => dayjs(date).format("DD MMM YYYY HH:mm:ss"),
  },
  {
    title: "Amount",
    dataIndex: "amount",
    key: "amount",
    render: (_, record: IInternalTransfer) => {
      return (
        <div className="currencyAmount">
          {toCurrencyNumber(record.amount)}&nbsp;
          <b>{record.currency}</b>
        </div>
      );
    },
  },
  {
    title: "From EntityId",
    dataIndex: "fromEntityId",
    key: "fromEntityId",
    render: (fromEntityId) => {
      return (
        <span>
          <Link
            to={
              fromEntityId === "house"
                ? "/app/house-account"
                : "/app/entity-detail/" + fromEntityId
            }
          >
            {fromEntityId}
          </Link>
        </span>
      );
    },
  },
  {
    title: "To EntityId",
    dataIndex: "toEntityId",
    key: "toEntityId",
    render: (toEntityId) => {
      return (
        <span>
          <Link
            to={
              toEntityId === "house"
                ? "/app/house-account"
                : "/app/entity-detail/" + toEntityId
            }
          >
            {toEntityId}
          </Link>
        </span>
      );
    },
  },
  {
    title: "Type",
    dataIndex: "type",
    key: "type",
  },
  {
    title: "Reason",
    dataIndex: "reason",
    key: "reason",
  },
  {
    title: "Status",
    dataIndex: "status",
    key: "status",
  },
  {
    title: "ID",
    dataIndex: "id",
    key: "id",
  },
];

interface IOwnProps {
  entityId: string;
  allowWrite: boolean;
  setShowInternalTransferForm: (value: boolean) => void;
}

const InternalTransfers: FC<IOwnProps> = ({
  entityId,
  allowWrite,
  setShowInternalTransferForm,
}) => {
  const [internalTransfersFromEntityId, setInternalTransfersFromEntityId] =
    useState([]);
  const [internalTransfersToEntityId, setInternalTransfersToEntityId] =
    useState([]);

  useEffect(() => {
    if (entityId) {
      const unsubscribeInternalTransfersFromEntityId =
        subscribeToInternalTransfersFromEntityId(entityId, (data) =>
          setInternalTransfersFromEntityId(data)
        );

      const unsubscribeInternalTransfersToEntityId =
        subscribeToInternalTransfersToEntityId(entityId, (data) =>
          setInternalTransfersToEntityId(data)
        );

      return () => {
        if (unsubscribeInternalTransfersFromEntityId) {
          unsubscribeInternalTransfersFromEntityId();
        }

        if (unsubscribeInternalTransfersToEntityId) {
          unsubscribeInternalTransfersToEntityId();
        }
      };
    }
  }, [entityId]);

  return (
    <Table
      title={() => (
        <Title level={2}>
          Internal Transfers
          {allowWrite && entityId && entityId !== "house" && (
            <Button
              type="primary"
              style={{ margin: "8px 0 0 0", float: "right" }}
              onClick={() => setShowInternalTransferForm(true)}
            >
              Create internal transfer
            </Button>
          )}
        </Title>
      )}
      columns={internalTransfersColumns}
      dataSource={[
        ...internalTransfersFromEntityId,
        ...internalTransfersToEntityId,
        // @ts-ignore
      ].sort((a, b) => new Date(b._created) - new Date(a._created))}
    />
  );
};

export default InternalTransfers;
