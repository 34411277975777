import db from "services/firestore";
import {
  IResponse,
  ILedgerTransactionUpdate,
  ILedgerTransactionInput,
  ILedgerTransactionDoc,
} from "types";
import { AxiosPrivateFirebaseInstance } from "settings";
import { openDocWithTimestamp, openQueryWithTimestamp } from "utils";
import dayjs from "dayjs";

export const createLedgerTransaction = async (
  newLedgerTransaction: Partial<ILedgerTransactionInput>
) =>
  AxiosPrivateFirebaseInstance.post<IResponse>(
    `/admin_actions/ledger-transactions`,
    newLedgerTransaction
  );

export interface UpdateLedgerTransactionParams {
  ledgerTransactionId: string;
  updateData: ILedgerTransactionUpdate;
}

export const updateLedgerTransaction = async ({
  ledgerTransactionId,
  updateData,
}: UpdateLedgerTransactionParams) =>
  AxiosPrivateFirebaseInstance.put<IResponse>(
    `/admin_actions/ledger-transactions/${ledgerTransactionId}`,
    updateData
  );

export const getLedgerRecord = async ({ ledgerTransactionId, entityId }: { ledgerTransactionId: string; entityId: string; }) => {
  try {
    const ledgerTransaction = await db
      .collection("ledgerTransactions")
      .doc(ledgerTransactionId)
      .get()
      .then((docSnapshot) => 
        openDocWithTimestamp(docSnapshot, ["_created", "_updated"]) as ILedgerTransactionDoc | undefined);

      console.log('ledgerTransaction', ledgerTransaction);
    return ledgerTransaction?._owner === entityId ? ledgerTransaction : undefined;
  } catch (error) {
    console.log(error);
  }
}

export const getLedgerRecordsFromSourceId = async ({ sourceId, entityId }: { sourceId: string; entityId: string; }) => {
  try {
    const ledgerTransactionsFromSourceId = await db
      .collection("ledgerTransactions")
      .where("ledger", "==", entityId)
      .where("sourceId", "==", sourceId)
      .get()
      .then((query) => openQueryWithTimestamp<ILedgerTransactionDoc>(query,  ["_created", "_updated"]));

      const ledgerTransactionsFromLinkedAsset = await db
      .collection("ledgerTransactions")
      .where("ledger", "==", entityId)
      .where("relatedInformation.linkedAsset.id", "==", sourceId)
      .get()
      .then((query) => openQueryWithTimestamp<ILedgerTransactionDoc>(query,  ["_created", "_updated"]));
      
      
    const allTransactions = [...ledgerTransactionsFromSourceId, ...ledgerTransactionsFromLinkedAsset];
    const sortedTransactionsByTransactionTimestampWithLatestFirst = allTransactions.sort((a, b) => {
      return dayjs(b.transactionTimestamp).unix() - dayjs(a.transactionTimestamp).unix();
    });
    
    console.log('ledgerTransactions', sortedTransactionsByTransactionTimestampWithLatestFirst);

    return sortedTransactionsByTransactionTimestampWithLatestFirst;
  } catch (error) {
    console.log(error);
  }
}