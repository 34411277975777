import { FC, useState, useEffect } from "react";
import firebase from "firebase/app";
import {
  Badge,
  Button,
  Descriptions,
  Form,
  Input,
  message,
  Table,
  Typography,
  Checkbox,
  DatePicker,
  Row,
  Col,
  Tag,
  Select,
} from "antd";
import dayjs from "dayjs";
import "./EntityDetails.css";
import {
  toCurrencyNumber,
  openDoc,
  openQuery,
  openQueryWithTimestamp,
  parseRateWithPrecision,
  TCustomRouteComponentProps,
} from "utils";
import { useParams } from "react-router";
import { Link } from "react-router-dom";
import { Firebase } from "services";

import db from "services/firestore";
import {
  BULK_PAYMENT_STATUS,
  CONTRACT_STATUS,
  IBalance,
  IBulkPayment,
  IContract,
  IEntityCashflowsSettings,
  ITransfer,
  TEntityPackages,
  TRANSFER_STATUS,
} from "types";

import ProfitAndLoss from "../../components/ProfitAndLoss/ProfitAndLoss";
import { getIntegrationColumns } from "components/IntegrationColumns/IntegrationColumns";
import { isPackageEnabled } from "utils/packages";
import { DATE_FORMAT } from "settings/variables";
import moment from "moment";
import DemoFunding from "./DemoFunding";
import DocViewer from "pages/Overview/components/DocViewer";
import FundingStatus from "components/FundingStatus/FundingStatus";
import SenderStatus from "components/SenderStatus/SenderStatus";
import { BulkPaymentChangeScheduledPaymentDate } from "./BulkPaymentChangeScheduledPaymentDate";
import Balances from "components/Balances/Balances";
import PaymentsCounts from "./PaymentsCounts";
import InternalTransfer from "./InternalTransfer";
import InternalTransfers from "components/InternalTransfers/InternalTransfers";

const { Title, Text } = Typography;

const subscribeToEntityCashflowsSettings = (entityId, callback) => {
  try {
    const unsubscribe = db
      .collection("entities")
      .doc(entityId)
      .collection("docs")
      .doc("cashflowsSettings")
      .onSnapshot((doc) => callback(openDoc(doc)));

    return unsubscribe;
  } catch (error) {
    console.log(error);
  }
};

const subscribeToEntity = (entityId, callback) => {
  try {
    const unsubscribe = db
      .collection("entities")
      .doc(entityId)
      .onSnapshot((doc) => callback(openDoc(doc)));

    return unsubscribe;
  } catch (error) {
    console.log(error);
  }
};

const subscribeToEntityDetails = (entityId, callback) => {
  try {
    const unsubscribe = db
      .collection("entities")
      .doc(entityId)
      .collection("onboarding")
      .doc("companyDetails")
      .onSnapshot((doc) => callback(openDoc(doc)));

    return unsubscribe;
  } catch (error) {
    console.log(error);
  }
};

const subscribeToUserDetails = (userId, callback) => {
  try {
    const unsubscribe = db
      .collection("users")
      .doc(userId)
      .onSnapshot((doc) => callback(openDoc(doc)));

    return unsubscribe;
  } catch (error) {
    console.log(error);
  }
};

const subscribeToRateContracts = (entityId, callback) => {
  try {
    const unsubscribe = db
      .collection("rateContracts")
      .where("_owner", "==", entityId)
      .onSnapshot((query) =>
        callback(openQueryWithTimestamp(query, ["_created"]))
      );

    return unsubscribe;
  } catch (error) {
    console.log(error);
  }
};

const subscribeToTransfers = (entityId, callback) => {
  try {
    const unsubscribe = db
      .collection("transfers")
      .where("_owner", "==", entityId)
      .onSnapshot((query) =>
        callback(openQueryWithTimestamp(query, ["_created"]))
      );

    return unsubscribe;
  } catch (error) {
    console.log(error);
  }
};

const subscribeToConversions = (entityId, callback) => {
  try {
    const unsubscribe = db
      .collection("conversions")
      .where("_owner", "==", entityId)
      .onSnapshot((query) =>
        callback(openQueryWithTimestamp(query, ["_created"]))
      );

    return unsubscribe;
  } catch (error) {
    console.log(error);
  }
};

const subscribeToBulkPayments = (entityId, callback) => {
  try {
    const unsubscribe = db
      .collection("bulkPayments")
      .where("_owner", "==", entityId)
      .onSnapshot((query) =>
        callback(openQueryWithTimestamp(query, ["_created"]))
      );

    return unsubscribe;
  } catch (error) {
    console.log(error);
  }
};

const subscribeToEntityTransactions = (entityId, callback) => {
  try {
    const unsubscribe = db
      .collection("entities")
      .doc(entityId)
      .collection("transactions")
      .orderBy("_created", "desc")
      .onSnapshot((query) =>
        callback(openQueryWithTimestamp(query, ["_created"]))
      );

    return unsubscribe;
  } catch (error) {
    console.log(error);
  }
};

const subscribeToEntityFundingEvents = (entity, callback) => {
  try {
    const unsubscribe = db
      .collection("eventsFromCc")
      .where("_event", "==", "funding")
      .where("account_id", "==", entity.externalRefs.ccId)
      .orderBy("_created", "desc")
      .onSnapshot((query) => callback(openQuery(query)));

    return unsubscribe;
  } catch (error) {
    console.log(error);
  }
};

const subscribeToEntitySenders = (entityId: string, callback) => {
  try {
    const unsubscribe = db
      .collection("senders")
      .where("_owner", "==", entityId)
      .orderBy("_created", "desc")
      .onSnapshot((query) => callback(openQuery(query)));

    return unsubscribe;
  } catch (error) {
    console.log(error);
  }
};

const subscribeToFunding = (entityId: string, callback) => {
  try {
    const unsubscribe = db
      .collection("funding")
      .where("_owner", "==", entityId)
      .orderBy("_created", "desc")
      .onSnapshot((query) => callback(openQuery(query)));

    return unsubscribe;
  } catch (error) {
    console.log(error);
  }
};

const prebooksColumns = [
  {
    title: "Created",
    dataIndex: "_created",
    key: "_created",
    render: (date) => dayjs(date).format("DD MMM YYYY HH:mm:ss"),
  },
  {
    title: "Sell",
    dataIndex: "sell",
    key: "sell",
    render: (text, record: IContract) => {
      return (
        <div className="currencyAmount">
          {toCurrencyNumber(record.totalAmount)}&nbsp;
          <b>{record.sellCurrency}</b>
        </div>
      );
    },
  },
  {
    title: "Buy",
    dataIndex: "buy",
    key: "buy",
    render: (text, record: IContract) => {
      return (
        <div className="currencyAmount">
          {toCurrencyNumber(record.buyAmount)}&nbsp;<b>{record.buyCurrency}</b>
        </div>
      );
    },
  },
  {
    title: "Rate",
    dataIndex: "rate",
    key: "rate",
    render: (value) => {
      return <>{parseRateWithPrecision(value)}</>;
    },
  },
  {
    title: "Flex (Fee)",
    dataIndex: "flexFeeAmount",
    key: "flexFeeAmount",
    render: (value) => {
      const isflex = !!value;
      return <>{isflex ? `Yes (${toCurrencyNumber(value)})` : "No"}</>;
    },
  },
  {
    title: "Notify via email",
    dataIndex: "whenFundsArriveAction",
    key: "whenFundsArriveAction",
    render: (value) => {
      return <>{value === "notifyViaEmail" ? "Yes" : "No"}</>;
    },
    sorter: (a, b) => {
      if (a.whenFundsArriveAction === b.whenFundsArriveAction) {
        return 0;
      }

      return -1;
    },
  },
  {
    title: "Convert and transfer",
    dataIndex: "whenFundsArriveAction",
    key: "whenFundsArriveAction",
    render: (value) => {
      return <>{value === "convertAndTransfer" ? "Yes" : "No"}</>;
    },
    sorter: (a, b) => {
      if (a.whenFundsArriveAction === b.whenFundsArriveAction) {
        return 0;
      }

      return -1;
    },
  },
  {
    title: "Remaining Amount",
    dataIndex: "remaining",
    key: "remaining",
    render: (text, record: IContract) => {
      return (
        <div className="currencyAmount">
          {toCurrencyNumber(record.remainingBuyAmount)}&nbsp;
          <b>{record.buyCurrency}</b>
        </div>
      );
    },
  },
  {
    title: "No Transfers",
    dataIndex: "numberOfTransfers",
    key: "numberOfTransfers",
  },
  {
    title: "Status",
    dataIndex: "status",
    key: "status",
  },
  {
    title: "Expiry",
    dataIndex: "expiryDate",
    key: "expiryDate",
  },
  {
    title: "ID",
    dataIndex: "id",
    key: "id",
    render: (text, record) => {
      return (
        <span>
          <Link to={"/app/contract/" + record.id}>{text}</Link>
        </span>
      );
    },
  },
];

const conversionsColumns = [
  {
    title: "Created",
    dataIndex: "_created",
    key: "_created",
    render: (date) => dayjs(date).format("DD MMM YYYY HH:mm:ss"),
  },
  {
    title: "Sell",
    dataIndex: "sell",
    key: "sell",
    render: (text, record: IContract) => {
      return (
        <div className="currencyAmount">
          {toCurrencyNumber(record.sellAmount)}&nbsp;
          <b>{record.sellCurrency}</b>
        </div>
      );
    },
  },
  {
    title: "Buy",
    dataIndex: "buy",
    key: "buy",
    render: (text, record: IContract) => {
      return (
        <div className="currencyAmount">
          {toCurrencyNumber(record.buyAmount)}&nbsp;<b>{record.buyCurrency}</b>
        </div>
      );
    },
  },
  {
    title: "Rate",
    dataIndex: "rate",
    key: "rate",
    render: (value) => {
      return <>{parseRateWithPrecision(value)}</>;
    },
  },
  {
    title: "Rate Type",
    dataIndex: "rateType",
    key: "rateType",
  },
  {
    title: "Conversion Date",
    dataIndex: "conversionDate",
    key: "conversionDate",
  },
  {
    title: "Status",
    dataIndex: "status",
    key: "status",
  },
  {
    title: "ID",
    dataIndex: "id",
    key: "id",
  },
];

const transactionsColumns = (allowWrite: boolean) => [
  {
    title: "Created",
    dataIndex: "_created",
    key: "_created",
    render: (date) => dayjs(date).format("DD MMM YYYY HH:mm:ss"),
  },
  {
    title: "Amount",
    dataIndex: "amount",
    key: "amount",
  },
  {
    title: "Currency",
    dataIndex: "currency",
    key: "currency",
  },
  {
    title: "Direction",
    dataIndex: "direction",
    key: "direction",
  },
  {
    title: "Matched",
    dataIndex: "matched",
    key: "matched",
    render: (val) => (val ? "TRUE" : "FALSE"),
  },
  {
    title: "Matched with",
    dataIndex: "matchedWith",
    key: "matchedWith",
  },
  {
    title: "Type",
    dataIndex: "type",
    key: "type",
  },
  {
    title: "ID",
    dataIndex: "id",
    key: "id",
  },
  ...(allowWrite
    ? [
        {
          title: "",
          dataIndex: "",
          key: "delete",
          render: (text, record: any) => {
            if (record.matched === false) {
              return (
                <>
                  <Button
                    onClick={() => deleteTransaction(record._owner, record.id)}
                  >
                    Delete
                  </Button>
                </>
              );
            }
            return <></>;
          },
        },
      ]
    : []),
];

const eventsColumns = [
  {
    title: "Created",
    dataIndex: "created_at",
    key: "created_at",
    render: (date) => dayjs(date).format("DD MMM YYYY HH:mm:ss"),
  },
  {
    title: "Action",
    dataIndex: "action",
    key: "action",
  },
  {
    title: "Amount",
    dataIndex: "amount",
    key: "amount",
  },
  {
    title: "Currency",
    dataIndex: "currency",
    key: "currency",
  },
  {
    title: "ID",
    dataIndex: "id",
    key: "id",
  },
];

const sendersColumns = [
  {
    title: "Created",
    dataIndex: "_created",
    key: "_created",
    render: (date) => dayjs(date.toDate()).format("DD MMM YYYY HH:mm:ss"),
  },
  {
    title: "Name",
    dataIndex: "name",
    key: "name",
    render: (text, record) => {
      return (
        <span>
          <Link to={"/app/sender-detail/" + record.id}>{text}</Link>
          {record.isEntityAccount && (
            <Tag color="green" style={{ margin: "0 0 0 8px", float: "right" }}>
              Entity account
            </Tag>
          )}
        </span>
      );
    },
  },
  {
    title: "Currency",
    dataIndex: "currency",
    key: "currency",
  },
  {
    title: "Country",
    dataIndex: "country",
    key: "country",
  },
  {
    title: "Status",
    dataIndex: "status",
    key: "status",
    render: (status) => <SenderStatus status={status} />,
  },
  {
    title: "ID",
    dataIndex: "id",
    key: "id",
  },
];

const fundingColumns = (
  setDocToView: (data: any) => void,
  allowWrite: boolean
) => [
  {
    title: "Created",
    dataIndex: "_created",
    key: "_created",
    render: (date) => dayjs(date.toDate()).format("DD MMM YYYY HH:mm:ss"),
  },
  {
    title: "Funded",
    dataIndex: "funded",
    key: "funded",
    render: (text, record) => {
      return (
        <div className="currencyAmount">
          {toCurrencyNumber(record.amount)}&nbsp;<b>{record.currency}</b>
        </div>
      );
    },
  },
  {
    title: "Status",
    dataIndex: "status",
    key: "status",
    render: (status) => <FundingStatus status={status} />,
  },
  {
    title: "Source ID",
    dataIndex: "sourceId",
    key: "sourceId",
  },
  ...(allowWrite
    ? [
        {
          title: "ID",
          dataIndex: "id",
          key: "id",
          render: (text, record) => {
            return (
              <span>
                <Link to="#" onClick={() => setDocToView(record)}>
                  {text}
                </Link>
              </span>
            );
          },
        },
      ]
    : []),
];

const deleteTransaction = async (entityId: string, transactionId: string) => {
  try {
    await db
      .collection("entities")
      .doc(entityId)
      .collection("transactions")
      .doc(transactionId)
      .delete();
    message.success("Transaction deleted");
  } catch (error) {
    message.warning(
      `Transaction could not be deleted. Error: ${error.message}`
    );
  }
};

const EntityOverview: FC<TCustomRouteComponentProps> = ({ allowWrite }) => {
  const [entity, setEntity] = useState(null);
  const [entityDetails, setEntityDetails] = useState(null);
  const [rateContracts, setRateContracts] = useState([]);
  const [transfers, setTransfers] = useState([]);
  const [conversions, setConversions] = useState([]);
  const [bulkPayments, setBulkPayments] = useState([]);
  const [transactions, setTransactions] = useState([]);
  const [fundingEvents, setFundingEvents] = useState([]);
  const [senders, setSenders] = useState([]);
  const [funding, setFunding] = useState([]);
  const [docToView, setDocToView] = useState(null);
  const [integrations, setIntegrations] = useState<Firebase.IIntegrationBase[]>(
    []
  );
  const [user, setUser] = useState(null);
  const [usedCredit, setUsedCredit] = useState(0);

  const [showDemoFunding, setShowDemoFunding] = useState(false);
  const [showInternalTransferForm, setShowInternalTransferForm] =
    useState(false);
  const [cashflowsSettings, setCashflowsSettings] =
    useState<IEntityCashflowsSettings | null>(null);

  useEffect(() => {
    if (entity) {
      const unsubscribe = subscribeToEntityCashflowsSettings(
        entity.id,
        (data) => setCashflowsSettings(data)
      );
      return () => {
        if (unsubscribe) {
          unsubscribe();
        }
      };
    }
  }, [entity]);

  const hasXeroIntegration = !!integrations.find(
    (integration) =>
      integration.system === "xero" && integration.status === "connected"
  );

  // @ts-ignore
  const { entity_id } = useParams();
  const descriptionForm = Form.useForm()[0];
  const cashflowsSettingsForm = Form.useForm()[0];

  useEffect(() => {
    if (cashflowsSettings) {
      cashflowsSettingsForm.setFieldsValue({ ...cashflowsSettings });
    }
  }, [cashflowsSettingsForm, cashflowsSettings]);

  useEffect(() => {
    if (entityDetails) {
      descriptionForm.setFieldsValue({ ...entityDetails });
    }
  }, [descriptionForm, entityDetails]);

  const packagesForm = Form.useForm()[0];
  const automationEnabledUntil = Form.useWatch(
    "automationEnabledUntil",
    packagesForm
  );
  const fxManagementEnabledUntil = Form.useWatch(
    "fxManagementEnabledUntil",
    packagesForm
  );

  const automationFreeTrial = Form.useWatch(
    "automationFreeTrial",
    packagesForm
  );
  const fxManagementFreeTrial = Form.useWatch(
    "fxManagementFreeTrial",
    packagesForm
  );

  const getPackagesFormInitialValues = (packages?: TEntityPackages) => {
    const values = {
      fxManagementFreeTrial: undefined,
      automationFreeTrial: undefined,
      automationEnabledUntil: undefined,
      fxManagementEnabledUntil: undefined,
    };

    if (packages) {
      if (!!packages?.automation?.freeTrialUntil) {
        values.automationFreeTrial = moment(packages.automation.freeTrialUntil);
      }
      if (!!packages?.fxManagement?.freeTrialUntil) {
        values.fxManagementFreeTrial = moment(
          packages.fxManagement.freeTrialUntil
        );
      }
      if (!!packages?.automation?.enabledUntil) {
        values.automationEnabledUntil = moment(
          packages.automation.enabledUntil
        );
      }
      if (!!packages?.fxManagement?.enabledUntil) {
        values.fxManagementEnabledUntil = moment(
          packages.fxManagement.enabledUntil
        );
      }
    }

    return values;
  };

  useEffect(() => {
    if (entity) {
      packagesForm.setFieldsValue(
        getPackagesFormInitialValues(entity.packages)
      );
    }
  }, [packagesForm, entity]);

  useEffect(() => {
    if (entity_id) {
      const unsubscribeEntityDetails = subscribeToEntityDetails(
        entity_id,
        (data) => setEntityDetails(data)
      );

      const unsubscribeEntity = subscribeToEntity(entity_id, (data) => {
        setEntity(data);
      });

      const unsubscribeRateContracts = subscribeToRateContracts(
        entity_id,
        (data) => setRateContracts(data)
      );

      const unsubscribeTransfers = subscribeToTransfers(entity_id, (data) =>
        setTransfers(data)
      );

      const unsubscribeConversions = subscribeToConversions(entity_id, (data) =>
        setConversions(data)
      );

      const unsubscribeBulkTransfers = subscribeToBulkPayments(
        entity_id,
        (data) => setBulkPayments(data)
      );

      const unsubscribeTransactions = subscribeToEntityTransactions(
        entity_id,
        (data) => setTransactions(data)
      );

      return () => {
        if (unsubscribeEntityDetails) {
          unsubscribeEntityDetails();
        }

        if (unsubscribeEntity) {
          unsubscribeEntity();
        }

        if (unsubscribeRateContracts) {
          unsubscribeRateContracts();
        }

        if (unsubscribeTransfers) {
          unsubscribeTransfers();
        }

        if (unsubscribeConversions) {
          unsubscribeConversions();
        }

        if (unsubscribeBulkTransfers) {
          unsubscribeBulkTransfers();
        }

        if (unsubscribeTransactions) {
          unsubscribeTransactions();
        }
      };
    }
  }, [entity_id]);

  useEffect(() => {
    if (entity) {
      const unsubscribe = subscribeToEntityFundingEvents(entity, (data) =>
        setFundingEvents(data)
      );

      return () => {
        if (unsubscribe) {
          unsubscribe();
        }
      };
    }
  }, [entity]);

  useEffect(() => {
    if (entity_id) {
      const unsubscribe = subscribeToEntitySenders(entity_id, (data) =>
        setSenders(data)
      );

      return () => {
        if (unsubscribe) {
          unsubscribe();
        }
      };
    }
  }, [entity_id]);

  useEffect(() => {
    if (entity_id) {
      const unsubscribe = subscribeToFunding(entity_id, (data) =>
        setFunding(data)
      );

      return () => {
        if (unsubscribe) {
          unsubscribe();
        }
      };
    }
  }, [entity_id]);

  useEffect(() => {
    if (entity) {
      Firebase.getIntegrations(entity.id).then((data) => setIntegrations(data));
    }
  }, [entity]);

  useEffect(() => {
    if (entity) {
      const unsubscribe = subscribeToUserDetails(entity._createdBy, (data) =>
        setUser(data)
      );

      return () => {
        if (unsubscribe) {
          unsubscribe();
        }
      };
    }
  }, [entity]);

  const transfersColumns = [
    {
      title: "Created",
      dataIndex: "_created",
      key: "_created",
      render: (date) => dayjs(date).format("DD MMM YYYY HH:mm:ss"),
    },
    {
      title: "Sell",
      dataIndex: "sell",
      key: "sell",
      render: (text, record: ITransfer) => {
        return (
          <div className="currencyAmount">
            {toCurrencyNumber(record.sellAmount)}&nbsp;
            <b>{record.sellCurrency}</b>
          </div>
        );
      },
    },
    {
      title: "Buy",
      dataIndex: "buy",
      key: "buy",
      render: (text, record: ITransfer) => {
        return (
          <div className="currencyAmount">
            {toCurrencyNumber(record.buyAmount)}&nbsp;
            <b>{record.buyCurrency}</b>
          </div>
        );
      },
    },
    {
      title: "Funding required",
      dataIndex: "payAmount",
      key: "payAmount",
      render: (text, record: ITransfer) => {
        return (
          <div className="currencyAmount">
            {toCurrencyNumber(record.payAmount)}&nbsp;
            <b>{record.sellCurrency}</b>
          </div>
        );
      },
    },
    {
      title: "Pay by (GMT)",
      dataIndex: "status",
      key: "status",
      render: (text, record: ITransfer) =>
        `${record.payByDate} ${record.payByTimeGMT}`,
    },
    {
      title: "Scheduled on",
      dataIndex: "scheduledPaymentDate",
      key: "scheduledPaymentDate",
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (text, record: ITransfer) => {
        let status = record.status.toString();
        status === TRANSFER_STATUS.awaitingPayment &&
          (status = "Awaiting Payment");
        status === TRANSFER_STATUS.paymentSent && (status = "Sent");
        return <>{status}</>;
      },
    },
    {
      title: "Recipient ID",
      dataIndex: "recipientId",
      key: "recipientId",
      render: (text, record: ITransfer) => {
        return (
          <span>
            <Link to={"/app/recipient-detail/" + record.recipientId}>
              {text}
            </Link>
          </span>
        );
      },
    },
    {
      title: "ID",
      dataIndex: "id",
      key: "id",
      render: (text, record) => {
        return (
          <span>
            <Link to={"/app/transfer/" + record.id}>{text}</Link>
          </span>
        );
      },
    },
    ...(allowWrite
      ? [
          {
            title: "",
            dataIndex: "",
            key: "funded",
            render: (text, record: ITransfer) => {
              if (record.status === "awaiting_payment") {
                return (
                  <>
                    <Button onClick={() => setToFunded(record, "transfers")}>
                      Set to Funded
                    </Button>
                  </>
                );
              }
            },
          },
        ]
      : []),
  ];

  const bulkPaymentsColumns = [
    {
      title: "Created",
      dataIndex: "_created",
      key: "_created",
      render: (date) => dayjs(date).format("DD MMM YYYY HH:mm:ss"),
    },
    {
      title: "Sell",
      dataIndex: "sell",
      key: "sell",
      render: (text, record: IBulkPayment) => {
        return (
          <div className="currencyAmount">
            {toCurrencyNumber(record.sellAmount)}&nbsp;
            <b>{record.sellCurrency}</b>
          </div>
        );
      },
    },
    {
      title: "Buy",
      dataIndex: "buy",
      key: "buy",
      render: (text, record: IBulkPayment) => {
        return (
          <>
            {record.buyAmountsBreakdown?.map((item) => {
              return (
                <div className="currencyAmount">
                  {toCurrencyNumber(item.amount)}&nbsp;
                  <b>{item.currency}</b>
                </div>
              );
            })}
          </>
        );
      },
    },
    {
      title: "Funding required",
      dataIndex: "payAmount",
      key: "payAmount",
      render: (text, record: IBulkPayment) => {
        return (
          <div className="currencyAmount">
            {toCurrencyNumber(record.payAmount)}&nbsp;
            <b>{record.sellCurrency}</b>
          </div>
        );
      },
    },
    {
      title: "Pay by (GMT)",
      dataIndex: "status",
      key: "status",
      render: (text, record: IBulkPayment) =>
        `${record.payByDate} ${record.payByTimeGMT}`,
    },
    {
      title: "Scheduled on",
      dataIndex: "scheduledPaymentDate",
      key: "scheduledPaymentDate",
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (text, record: IBulkPayment) => {
        let status = record.status.toString();
        status === TRANSFER_STATUS.awaitingPayment &&
          (status = "Awaiting Payment");
        status === TRANSFER_STATUS.paymentSent && (status = "Sent");
        return <>{status}</>;
      },
    },
    {
      title: "ID",
      dataIndex: "id",
      key: "id",
      render: (text, record) => {
        return (
          <span>
            <Link to={"/app/bulk-payment/" + record.id}>{text}</Link>
          </span>
        );
      },
    },
    ...(allowWrite
      ? [
          {
            title: "Actions",
            dataIndex: "",
            key: "funded",
            render: (text, record: IBulkPayment) => {
              if (record.status === "awaiting_payment") {
                return (
                  <>
                    <Button onClick={() => setToFunded(record, "bulkPayments")}>
                      Set to Processing
                    </Button>
                    <BulkPaymentChangeScheduledPaymentDate
                      bulkPaymentId={record.id}
                      entityId={entity_id}
                      currentDate={record.scheduledPaymentDate}
                    />
                  </>
                );
              }
            },
          },
        ]
      : []),
  ];

  const integrationsColumns = getIntegrationColumns(entity_id);

  const setToFunded = async (
    transfer: ITransfer | IBulkPayment,
    transferCollection: "transfers" | "bulkPayments"
  ) => {
    try {
      const { data: response } = await Firebase.refreshBalances(entity_id);
      if (!response.success) {
        throw new Error(response.message);
      }
      const returnedBalances = response.data;
      const balance: IBalance = returnedBalances.find(
        (balance) => balance.currency === transfer.sellCurrency
      );
      if (!balance || balance.amount < transfer.payAmount) {
        message.error(
          `Not enough balance to set the transfer to funded. Required ${toCurrencyNumber(
            transfer.payAmount
          )} but only ${toCurrencyNumber(balance.amount)} is available.`,
          5
        );
        return;
      }
    } catch (error) {
      message.error(
        "Could not get the balance to check if we can set the transfer to funded"
      );
      return;
    }

    // Update Transfer to Funded
    const updateDoc = {
      status:
        transferCollection === "transfers"
          ? TRANSFER_STATUS.funded
          : BULK_PAYMENT_STATUS.processing,
      _updated: firebase.firestore.FieldValue.serverTimestamp(),
      _updatedBy: "Force status change to Funded via Admin",
      _version: firebase.firestore.FieldValue.increment(1),
    };
    try {
      console.log(transferCollection, transfer.id, updateDoc);
      await db
        .collection(transferCollection)
        .doc(transfer.id)
        .set(updateDoc, { merge: true });

      message.success("Transfer status set to Funded.");
    } catch (error) {
      message.error(`Could not set status to funded. Error: ${error.message}`);
    }

    // Update Transaction to Matched
    try {
      const transactionQueryKey =
        transferCollection === "transfers" ? "_transferId" : "_bulkPaymentId";
      const transactionResults = await db
        .collection("entities")
        .doc(transfer._owner)
        .collection("transactions")
        .where(transactionQueryKey, "==", transfer.id)
        .get()
        .then((query) => openQuery(query) as { id: string }[]);
      if (!transactionResults || transactionResults.length === 0) {
        throw new Error("No transactions found for this Transfer");
      }
      if (transactionResults.length > 1) {
        throw new Error(
          "Too many transaction results found for this transfer, so unable to determine which one should be updated"
        );
      }
      const transactionId = transactionResults[0].id;

      await db
        .collection("entities")
        .doc(transfer._owner)
        .collection("transactions")
        .doc(transactionId)
        .set(
          { matched: true, matchedWith: "Manual update to Funded via Admin" },
          { merge: true }
        );
    } catch (error) {
      message.warning(
        `Transfer set to funded but could not update transaction to matched. Error: ${error.message}`
      );
    }
  };

  const getAvgLast3Transfers = (transfers: any[]) => {
    if (transfers.length === 0) {
      return 0;
    }
    const avg =
      transfers.slice(0, 3).reduce((a, b) => a + b.sellAmount, 0) /
      transfers.slice(0, 3).length;
    return avg.toFixed(2);
  };

  const getSummaryOfCountriesSentTo = (transfers: any[]) => {
    const summaryObj = {};
    transfers.forEach((transfer) => {
      if (summaryObj[transfer.recipient.accountCountry.toUpperCase()]) {
        summaryObj[transfer.recipient.accountCountry.toUpperCase()] += 1;
      } else {
        summaryObj[transfer.recipient.accountCountry.toUpperCase()] = 1;
      }
    });
    const summaryString = Object.keys(summaryObj).map(
      (key, value) => `${key}(x${summaryObj[key]}), `
    ); //summaryArray.map(item => item.value + ", ");
    return summaryString;
  };

  useEffect(() => {
    if (rateContracts) {
      const activeContracts = rateContracts.filter(
        (contract) => contract.status !== CONTRACT_STATUS.cancelled
      );
      const used = activeContracts
        .map(
          (contract) =>
            contract.totalAmount *
            (contract.remainingBuyAmount / contract.buyAmount)
        )
        .reduce((a, b) => a + b, 0);
      setUsedCredit(used);
    }
  }, [rateContracts]);

  return (
    <div>
      <DocViewer
        doc={docToView}
        isVisible={!!docToView}
        onClose={() => setDocToView(null)}
      />

      {showDemoFunding && (
        <DemoFunding
          onBehalfOfId={entity?.externalRefs?.ccOnBehalfOfId}
          onClose={() => setShowDemoFunding(false)}
          isVisible={!!showDemoFunding}
        />
      )}

      {showInternalTransferForm && (
        <InternalTransfer
          entity={entity}
          onClose={() => setShowInternalTransferForm(false)}
          isVisible={!!showInternalTransferForm}
        />
      )}

      {entity && (
        <>
          <Title>
            {entity.name}

            {allowWrite && (
              <Button
                danger
                style={{ margin: "8px 0 0 0", float: "right" }}
                onClick={async () => {
                  await Firebase.actOnBehalfOfEntity(entity_id);
                }}
              >
                Act on behalf of Entity
              </Button>
            )}
          </Title>
          <span style={{ display: "inline-block" }}>
            <i>
              <Text>
                {entityDetails?.companyRegistrarId &&
                  `(${entityDetails.companyRegistrarId}) - `}
                {user &&
                  `Operated by ${user?.name} (${user.email}) (${user.id})`}
              </Text>
              <br />
              CC IDs:{" "}
              {`${entity?.externalRefs?.ccId} (${entity?.externalRefs?.ccShortReference}) - OnBehalfOf: ${entity?.externalRefs?.ccOnBehalfOfId}`}
            </i>
          </span>
          <span
            style={{ float: "right", marginRight: "20px", textAlign: "right" }}
          >
            {entityDetails?.ddNextDate && (
              <span>
                Next DD is due on{" "}
                {dayjs(entityDetails.ddNextDate, "YYYY-MM-DD").format(
                  DATE_FORMAT
                )}
                <br />
              </span>
            )}
            <Link to={`/app/onboarding-detail/${entity_id}`}>
              Onboarding Details
            </Link>
            <Link
              to={`/app/write-log-detail/${entity_id}`}
              style={{ marginLeft: 10 }}
            >
              Write event logs
            </Link>
          </span>
          {entityDetails && (
            <Form
              form={descriptionForm}
              initialValues={{
                ddType: entityDetails.ddType,
                creditLimit: entityDetails.creditLimit,
                approvedQuarterlyFx: entityDetails.approvedQuarterlyFx,
                shouldShowReports: !!entity?.shouldShowReports,
              }}
              onFinish={async (values) => {
                console.log("onFinish", values);

                try {
                  await Firebase.updateEntityOnboardingCompanyDetails(
                    entity_id,
                    values
                  );
                  message.success("Updated entity onboarding company details.");
                } catch (error) {
                  message.error(error.message);
                }
              }}
            >
              <Descriptions bordered>
                <Descriptions.Item label="Nature of Business">
                  {entityDetails.natureOfBusiness}
                </Descriptions.Item>

                <Descriptions.Item label="VAT">
                  {entityDetails.vat ? entityDetails.vatNumber : "NO"}
                </Descriptions.Item>
                <Descriptions.Item label="Type of Due Diligence">
                  {entityDetails.ddType === "cdd" && "Client DD"}
                  {entityDetails.ddType === "sdd" && "Simplified DD"}
                  {entityDetails.ddType === "edd" && "Enhanced DD"}
                </Descriptions.Item>

                <Descriptions.Item label="Purpose of Account">
                  {entityDetails.purposeOfAccount}
                </Descriptions.Item>
                <Descriptions.Item
                  label="Expected Annual Turnover"
                  style={{ whiteSpace: "nowrap" }}
                >
                  £{toCurrencyNumber(entity.expectedAnnualTurnover, 0)}
                </Descriptions.Item>
                <Descriptions.Item label="Credit Limit">
                  <Form.Item
                    name="creditLimit"
                    shouldUpdate
                    style={{ marginBottom: "0px" }}
                  >
                    <Input prefix="£" placeholder="Credit Limit" />
                  </Form.Item>
                </Descriptions.Item>

                <Descriptions.Item label="URL or Social Media">
                  {entity.website}
                </Descriptions.Item>
                <Descriptions.Item
                  label="Expected Monthly FX"
                  style={{ whiteSpace: "nowrap" }}
                >
                  £{toCurrencyNumber(entity.expectedFxTurnoverUpperLimit, 0)}
                </Descriptions.Item>
                <Descriptions.Item label="Approved Quarterly Max">
                  <Form.Item
                    name="approvedQuarterlyFx"
                    style={{ marginBottom: "0px" }}
                  >
                    <Input
                      prefix="£"
                      placeholder="Approved Quarterly FX value"
                    />
                  </Form.Item>
                </Descriptions.Item>

                <Descriptions.Item label=""> </Descriptions.Item>

                <Descriptions.Item label="Reports enabled">
                  <Form.Item
                    name="shouldShowReports"
                    style={{ marginBottom: "0px" }}
                    valuePropName="checked"
                  >
                    <Checkbox />
                  </Form.Item>
                </Descriptions.Item>

                <Descriptions.Item label="Conversion Fee Rate Override">
                  <Form.Item
                    name="conversionFeeRateOverride"
                    shouldUpdate
                    style={{ marginBottom: "0px" }}
                  >
                    <Input placeholder="Default is 0.004" />
                  </Form.Item>
                </Descriptions.Item>

                <Descriptions.Item
                  label={
                    <>
                      Status{" "}
                      {entity.enabled && <Tag color="green">ENABLED</Tag>}
                      {!entity.enabled && entity.status === "onboarded" && (
                        <Tag color="red">DISABLED</Tag>
                      )}
                    </>
                  }
                >
                  {entity.enabled === false &&
                    entity.status === "onboardingStep4Completed" && (
                      <Badge status="processing" text="Onboarding Review" />
                    )}
                  {entity.enabled === true && entity.onboarded === true && (
                    <Badge status="success" text="Onboarding Approved" />
                  )}
                  {entity.enabled === false && entity.onboarded === false && (
                    <Badge status="error" text="Onboarding Rejected" />
                  )}
                  {entity.enabled === false &&
                    entity.onboarded === false &&
                    entity.status !== "onboardingStep4Completed" && (
                      <Badge
                        status="warning"
                        text="Registration Not Completed"
                      />
                    )}

                  {allowWrite && (
                    <Button
                      type="default"
                      style={{ float: "right" }}
                      htmlType="submit"
                    >
                      Update
                    </Button>
                  )}
                </Descriptions.Item>
              </Descriptions>
              <br />

              <Title level={4}>Usage</Title>

              <Descriptions bordered>
                <Descriptions.Item label="Currencies used">
                  {transfers
                    .map((transfer) => transfer.buyCurrency + ", ")
                    .filter(
                      (value, index, self) => self.indexOf(value) === index
                    )}
                </Descriptions.Item>
                <Descriptions.Item
                  label="Largest payment to date"
                  style={{ whiteSpace: "nowrap" }}
                >
                  £{" "}
                  {transfers.length === 0
                    ? 0
                    : toCurrencyNumber(
                        Math.max.apply(
                          Math,
                          transfers.map(function (o) {
                            return o.sellAmount;
                          })
                        ),
                        2
                      )}
                </Descriptions.Item>
                <Descriptions.Item
                  label="Credit remaining"
                  style={{ whiteSpace: "nowrap" }}
                >
                  £ {toCurrencyNumber(entity.remainingCredit, 0)}
                </Descriptions.Item>

                <Descriptions.Item label="Countries expected to send to">
                  {"Projected: "}
                  {entityDetails.countriesSendingMoneyTo.map(
                    (country: string) => `${country.toUpperCase()}, `
                  )}
                  <br />
                  {"Actual: "}
                  {getSummaryOfCountriesSentTo(transfers)}
                </Descriptions.Item>
                <Descriptions.Item
                  label="Avg last 3 payments"
                  style={{ whiteSpace: "nowrap" }}
                >
                  £ {toCurrencyNumber(getAvgLast3Transfers(transfers), 0)}
                </Descriptions.Item>
                <Descriptions.Item
                  label="Credit Limit used"
                  style={{ whiteSpace: "nowrap" }}
                >
                  £ {toCurrencyNumber(usedCredit, 0)}
                </Descriptions.Item>
              </Descriptions>
            </Form>
          )}
        </>
      )}
      <br />
      <Title level={4}>Cashflows Settings</Title>
      <Form
        form={cashflowsSettingsForm}
        onFinish={async (values) => {
          await Firebase.updateEntityCashflowsSettings(entity_id, values);
          message.success("Updated entity cashflows settings.");
        }}
      >
        <Descriptions bordered>
          <Descriptions.Item label="Cashflows At Risk Netting Period">
            <Form.Item
              name="cashflowsAtRiskNettingPeriod"
              shouldUpdate
              style={{ marginBottom: "0px" }}
            >
              <Select
                options={[
                  { value: "month", label: "Month" },
                  { value: "week", label: "Week" },
                ]}
              />
            </Form.Item>
          </Descriptions.Item>

          <Descriptions.Item>
            <Button type="default" style={{ float: "right" }} htmlType="submit">
              Update
            </Button>
          </Descriptions.Item>
        </Descriptions>
      </Form>

      {entity?.settings !== "onbaorded" && <br />}

      <Title level={4}>Packages</Title>
      <Form
        form={packagesForm}
        initialValues={getPackagesFormInitialValues(entity?.packages)}
        onFinish={async (values) => {
          try {
            await Firebase.updateEntityPackages(entity_id, values);
            message.success("Updated entity packages.");
          } catch (error) {
            console.log(error);
            message.error(error.message);
          }
        }}
      >
        <Descriptions bordered>
          <Descriptions.Item label="Automation" span={2}>
            <Row gutter={16}>
              <Col>
                <Form.Item
                  name="automationEnabledUntil"
                  style={{ marginBottom: "6px" }}
                  label="Enabled until"
                >
                  <DatePicker
                    showTime={{ format: "HH:mm:ss" }}
                    format={DATE_FORMAT}
                    placeholder="Enabled until"
                  />
                </Form.Item>
              </Col>
              <Col>
                {isPackageEnabled(automationEnabledUntil) ? (
                  <Badge status="success" />
                ) : (
                  <Badge status="error" />
                )}
              </Col>
            </Row>
            <Row gutter={16}>
              <Col>
                <Form.Item
                  name="automationFreeTrial"
                  style={{ marginBottom: "6px" }}
                  label="Free trial"
                >
                  <DatePicker
                    showTime={{ format: "HH:mm:ss" }}
                    format={DATE_FORMAT}
                    placeholder="Free trial until"
                    style={{ marginLeft: "27px" }}
                  />
                </Form.Item>
              </Col>
              <Col>
                {isPackageEnabled(automationFreeTrial) ? (
                  <Badge status="success" />
                ) : (
                  <Badge status="error" />
                )}
              </Col>
            </Row>
          </Descriptions.Item>
          <Descriptions.Item label="FX Management" span={2}>
            <Row gutter={16}>
              <Col>
                <Form.Item
                  name="fxManagementEnabledUntil"
                  style={{ marginBottom: "6px" }}
                  label="Enabled until"
                >
                  <DatePicker
                    format={DATE_FORMAT}
                    placeholder="Enabled until"
                  />
                </Form.Item>
              </Col>
              <Col>
                {isPackageEnabled(fxManagementEnabledUntil) ? (
                  <Badge status="success" />
                ) : (
                  <Badge status="error" />
                )}
              </Col>
            </Row>
            <Row gutter={16}>
              <Col>
                <Form.Item
                  name="fxManagementFreeTrial"
                  style={{ marginBottom: "6px" }}
                  label="Free trial"
                >
                  <DatePicker
                    showTime={{ format: "HH:mm:ss" }}
                    format={DATE_FORMAT}
                    placeholder="Free trial until"
                    style={{ marginLeft: "27px" }}
                  />
                </Form.Item>
              </Col>
              <Col>
                {isPackageEnabled(fxManagementFreeTrial) ? (
                  <Badge status="success" />
                ) : (
                  <Badge status="error" />
                )}
              </Col>
            </Row>
          </Descriptions.Item>

          <Descriptions.Item label="" span={3}>
            {allowWrite && (
              <Button
                type="default"
                style={{ float: "right" }}
                htmlType="submit"
              >
                Update
              </Button>
            )}
          </Descriptions.Item>
        </Descriptions>
      </Form>
      <br />

      {entity && entity.status === "onboarded" && (
        <>
          <Balances entityId={entity_id} />

          <Table
            title={() => <Title level={2}>Contracts</Title>}
            columns={prebooksColumns}
            dataSource={rateContracts}
          />

          <PaymentsCounts entity={entity} />

          <Table
            title={() => <Title level={2}>Transfers</Title>}
            columns={transfersColumns}
            dataSource={transfers}
          />

          <InternalTransfers
            entityId={entity_id}
            allowWrite={allowWrite}
            setShowInternalTransferForm={setShowInternalTransferForm}
          />

          <Table
            title={() => <Title level={2}>Bulk Transfers</Title>}
            columns={bulkPaymentsColumns}
            dataSource={bulkPayments}
          />

          <Table
            title={() => <Title level={2}>Conversions</Title>}
            columns={conversionsColumns}
            dataSource={conversions}
          />

          <Table
            title={() => <Title level={2}>Funding Matching Transactions</Title>}
            columns={transactionsColumns(allowWrite)}
            dataSource={transactions}
          />

          <Table
            title={() => (
              <Title level={2}>
                Funding events
                {allowWrite &&
                  entity?.externalRefs?.ccOnBehalfOfId &&
                  process.env.REACT_APP_ENVIRONMENT_NAME !== "PROD" && (
                    <Button
                      type="primary"
                      style={{ margin: "8px 0 0 0", float: "right" }}
                      onClick={() => setShowDemoFunding(true)}
                    >
                      Create fake funding
                    </Button>
                  )}
              </Title>
            )}
            columns={eventsColumns}
            dataSource={fundingEvents}
          />

          <Table
            title={() => <Title level={2}>Senders</Title>}
            columns={sendersColumns}
            dataSource={senders}
          />

          <Table
            title={() => <Title level={2}>Funding</Title>}
            columns={fundingColumns(setDocToView, allowWrite)}
            dataSource={funding}
          />
        </>
      )}

      <Table
        title={() => (
          <Title level={2}>
            Integrations
            {allowWrite && (
              <Button
                style={{ margin: "10px 25px 0px 0px", float: "right" }}
                onClick={async () => {
                  await Firebase.runBacktester(entity_id);
                }}
              >
                Run Backtester calculations
              </Button>
            )}
            {hasXeroIntegration && <ProfitAndLoss entityId={entity_id} />}
          </Title>
        )}
        columns={integrationsColumns}
        dataSource={integrations}
      />
    </div>
  );
};

export default EntityOverview;
