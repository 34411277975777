import db from "services/firestore";
import { AxiosPrivateFirebaseInstance } from "settings";
import { IResponse } from "types";
import { Notify, openQueryWithTimestamp } from "utils";

export interface CreateFakeFundingParams {
  fakeFunding: any;
}

export const createFakeFunding = async ({
  fakeFunding,
}: CreateFakeFundingParams) => {
  try {
    const { data } = await AxiosPrivateFirebaseInstance.post<IResponse>(
      "/admin_actions/funding/fake",
      fakeFunding
    );

    if (data.success) {
      return data;
    } else {
      Notify.error(data.message);
    }
  } catch (error) {
    Notify.error(error.message);
  }
};

export interface SubscribeToFundingParams {
  callback: (users: any) => void;
}

export const subscribeToFunding = ({ callback }: SubscribeToFundingParams) => {
  const subscribe = db
    .collection("funding")
    .onSnapshot((query) =>
      callback(openQueryWithTimestamp(query, ["_created", "_updated"]))
    );

  return subscribe;
};

export const markSenderAsPayoutAccount = async (
  senderId: string,
  entityId: string
) =>
  AxiosPrivateFirebaseInstance.post<IResponse>(
    "admin_actions/funding/mark-sender-as-payout-account",
    { senderId, entityId }
  );

export const refreshBalances = async (entityId: string) =>
  AxiosPrivateFirebaseInstance.post(`/admin_actions/${entityId}/cc-balances/refresh`);
