import { TAdminRole } from "utils";

export * from "./ledger";
export * from "./entityUsersAndInvites";
export * from "./networking";

type TExternalSystemType =
  | "xero"
  | "orderWise"
  | "msDynamics"
  | "netSuite"
  | "externalApi"
  | "csvUpload";

export type TRoles = TAdminRole[];

export interface IUser {
  id: string;
  email: string;
  name: string;
  entityId: string;
  enabled: boolean;
  status: string;
  roles?: TRoles;
}

export interface IAdminUser {
  uid: string;
  email: string;
  emailVerified: boolean;
  displayName: string;
  disabled: boolean;
  customClaims: Record<TAdminRole, boolean>;
}

export enum CONTRACT_STATUS {
  awaiting_deposit = "awaiting_deposit",
  deposit_overdue = "deposit_overdue",
  ready_to_use = "ready_to_use",
  used = "used",
  cancelled = "cancelled",
  expired = "expired",
}

export interface IContractInput {
  sellAmount: number;
  sellCurrency: string;
  buyAmount: number;
  buyCurrency: string;
  rate: number; // 1.2345
  expiryDate: string; // YYYY-MM-DD
  useableFromDate?: string;

  feeRate: number; // 0.0089 for 0.89%
  feeAmount: number;
  flexFeeAmount?: number;

  totalAmount: number; // sellAmount + feeAmount
  prepaymentAmount?: number; // flexFeeAmount

  bookingName?: string;

  invoiceId?: string;
}

export interface IContract extends IContractInput, ISystemFields {
  status: CONTRACT_STATUS;
  remainingBuyAmount: number;
  numberOfTransfers: number;
  prepaymentPayByDate: string;
  prepaymentPayByTimeGMT: string;
  externalRefs?: { ccId: string };
  whenFundsArriveAction?: "notifyViaEmail" | "convertAndTransfer";
}

export interface IPrebookRates {
  midMarketRate: number;
  transactionFeeRate: number;
  contractFeeRate: number;
}

export interface IPrebookAmounts {
  contractAmount: number;
  feeAmount: number;
  totalAmount: number;
}

export interface IDeposit {
  currency: string;
  amount: number;
  paidByDate: string; //'YYYY-MM-DD
}

export interface ICurrency {
  id: string;
  code: string;
  symbol: string;
  name: string;
  country: string;
  countryCode: string;
  sell: boolean; //If true, then allowed to use as a payment, e.g. show in "Amount to convert"
  buy: boolean; //If true, then allowed to convert into, e.g. show in "Amount to send"
}

export interface IRateAnalysis {
  months: number;
  percentile: number;
  lowest: number;
  highest: number;
  rateRating: number;
  rateRatingText: string;
}

export interface IRateAnalyses {
  requestSummary: {
    buyCurrency: string;
    sellCurrency: string;
    buyAmount: number;
    currentSellAmount: number;
  };
  data: IRateAnalysis[];
}

export interface IPotentialSaving {
  months: number;
  extraCost: number;
  extraCostPct: number;
  rateRating: number;
  rateRatingText: string;
}

export interface IPotentialSavingWithDate
  extends Omit<IPotentialSaving, "months"> {
  tenorDays: number;
}

export interface IPotentialSavings {
  requestSummary: {
    buyCurrency: string;
    sellCurrency: string;
    buyAmount: number;
  };
  data: IPotentialSaving[];
}

export interface IPotentialSavingsWithDate {
  requestSummary: {
    buyCurrency: string;
    sellCurrency: string;
    buyAmount: number;
    tenorDate: string;
  };
  data: IPotentialSavingWithDate[];
}

export interface IRateAnalysisStable {
  id: number;
  rate: number;
  waitAndSeeAmount: number;
  prebookAmount: number;
  difference: number;
}

export interface IRateAnalysesStable {
  requestSummary: {
    buyAmount: number;
    rate: number;
    deposit: number;
    bookingFee: number;
    conversionFee: number;
  };
  data: IRateAnalysisStable[];
}

export interface IRatings {
  requestSummary: {
    buyCurrency: string;
    sellCurrency: string;
    currentRate: number;
  };
  data: {
    rateRating: number;
    rateRatingText: string;
    potentialSavingsRating: number;
    potentialSavingsRatingText: string;
  };
}

export interface IEntity extends ISystemFields {
  companyCountry: string;
  companyType: ENTITY_TYPE;
  enabled: boolean;
  entityCurrency: string;
  expectedAnnualTurnover: number;
  expectedFxTurnoverUpperLimit: number;
  externalRefs: {
    ccId: string;
    ccOnBehalfOfId: string;
    ccShortReference: string;
  };
  name: string;
  status: ENTITY_STATUS;
  subStatusAfterOnboarding?: ENTITY_SUB_STATUS_AFTER_ONBOARDING;
  website?: string;
  onboarded?: boolean;
  remainingCredit?: number;
  packages?: TEntityPackages;
}

export type TEntityPackageKeys = "global" | "automation" | "fxManagement";
export interface IEntityPackage {
  freeTrialUntil?: string;
  enabledUntil?: string;
}
export type TEntityPackages = Record<TEntityPackageKeys, IEntityPackage>;

export enum ENTITY_STATUS {
  onboardingNotStarted = "onboardingNotStarted",
  onboardingStep2Completed = "onboardingStep2Completed",
  onboardingStep3Completed = "onboardingStep3Completed",
  onboardingStep4Completed = "onboardingStep4Completed",
  onboarded = "onboarded",
  rejected = "rejected",
}

export enum ENTITY_SUB_STATUS_AFTER_ONBOARDING {
  live = "live",
  dormant = "dormant",
  exited = "exited",
  onHold = "onHold",
}

export enum ENTITY_TYPE {
  soleTrader = "soleTrader",
  limitedCompany = "limitedCompany",
  publicLimitedCompany = "publicLimitedCompany",
  simplePartnership = "simplePartnership",
  limitedLiabilityPartnership = "limitedLiabilityPartnership",
}

export enum RECIPIENT_TYPE {
  individual,
  company,
}

export enum PAYMENT_TYPE {
  local,
  iban,
  swift,
}

export enum ROUTING_TYPE {
  sort_code,
  aba,
  bsb_code,
  institution_no,
  bank_code,
  branch_code,
  clabe,
  cnaps,
  ifsc,
}

export enum RECIPIENT_STATUS {
  draft = "draft",
  initiated = "initiated",
  requiresReview = "requires_review",
  approved = "approved",
  rejected = "rejected",
  deleted = "deleted",
}

export type TContactReviewalStatus = "new" | "updated" | "reviewed";

export interface IRecipient {
  _owner: string;
  _created: any;
  _createdBy: string;
  id: string;
  externalRefs?: { ccId?: string };
  currency: string; // 3 char uppercase
  // CONTACT
  recipientEntityType: RECIPIENT_TYPE; // Individual or Company
  recipientCountry: string; // 2 char uppercase
  recipientName: string; // 1-255 char
  // We use one field for the name
  // For entity_type=individual -> recipientName has to be two words or more
  //ACCOUNT
  accountCountry: string; // 2 char uppercase
  paymentType: PAYMENT_TYPE; // Local, IBAN or SWIFT
  //We need one of these 3, depending on the paymentType
  accountNumber: string; // Account Number or IBAN Number
  bicSwift?: string; // BIC/SWIFT Code
  routingType: ROUTING_TYPE; // Local payment routing system, e.g. Sort Code in UK, Routing in US
  routingNumber: string; // Sort/Routing Code
  //REQUIRED FOR PRIORITY PAYMENTS | paymentType === PAYMENT_TYPE.priority
  recipientAddress?: string;
  recipientCity?: string;
  //OPTIONAL
  recipientEmail?: string;
  recipientPostcode?: string;
  bankName?: string;
  recipientStateOrProvince?: string;
  status: RECIPIENT_STATUS;
  enabled: boolean;
  statusDetails?: string;
  reviewalStatus: TContactReviewalStatus;
  lastReviewed: any;
}

// Transfers
export interface ITransferInput {
  sellAmount: number;
  sellCurrency: string;
  buyAmount: number;
  buyCurrency: string;
  rate: number; // 1.2345
  rateType: RATE_TYPE;
  /** When the entity has an FX Rate conversion fee override, then the conversion fee rate is stored here */
  conversionFeeRate?: number;
  payAmount: number;

  purpose?: string;
  purposeCode?: string;
  transferType: TRANSFER_TYPE;

  recipientId: string;

  contractId?: string;
  bulkPaymentId?: string;
  paymentRunId?: string;
  scheduledPaymentDate?: string;
  priorityTransferFeeAmount?: number;
  reference?: string;

  /** @deprecated Not used anymore */
  invoiceId?: string;
  invoiceIds?: string[];
}

export interface ITransfer extends ITransferInput, ISystemFields {
  status: TRANSFER_STATUS;
  statusReason?: string;
  payByDate: string;
  payByTimeGMT: string;
  recipient: IRecipient;
  externalRefs?: {
    ccId?: string;
    ccConversionId?: string;
    ccRecipientId: string;
  };
  mt103?: string;
  emailReminderSentOn?: any;
}

interface IBulkPaymentBase {
  sellCurrency: string;
  sellAmount: number;
  paymentFee: number;
  payAmount: number;
  payByDate: string;
  payByTimeGMT: string;
  buyAmountsBreakdown: {
    currency: string;
    amount: number;
    rate: number;
    sellAmount: number;
  }[];
}

export interface IBulkPayment
  extends IBulkPaymentBase,
    IBulkPaymentSystemFields {
  externalRefs?: {
    openBankingId: string;
  };
  paymentRunId?: string;
  scheduledPaymentDate?: string;
  fundedTimestamp: any;
  localCount?: number;
  swiftCount?: number;
  openBankingCount?: number;
  invoicesCount?: number;
}

interface IBulkPaymentSystemFields extends ISystemFields {
  status: BULK_PAYMENT_STATUS;
}

export enum BULK_PAYMENT_STATUS {
  awaitingPayment = "awaiting_payment",
  /** Client has initiated funding, e.g. via Open Banking, but we've not received the funds */
  fundingInitiated = "funding_initiated",
  funded = "funded",
  processing = "processing",
  completed = "completed",
  cancelled = "cancelled",
  transfersFailed = "transfers_failed",
}

export interface ISystemFields {
  id: string;
  _shortId: string; // e.g. TNG7F4R
  _created: any;
  _createdBy: string;
  _updated?: any;
  _updatedBy?: string;
  _version: any;
  _owner: string;
}

export interface ICreateTransferReturn {
  success: boolean;
  id: string;
  data: ITransfer;
}

export enum RATE_TYPE {
  market = "market",
  prebooked = "prebook",
  noRate = "noRate",
}

export enum TRANSFER_TYPE {
  regular = "regular",
  priority = "priority",
}

export enum TRANSFER_STATUS {
  /** Instructions have been received successfully and follow-up processes initiated */
  initiated = "initiated",
  /** Once the conversion has been created the transfer waits for funding */
  awaitingPayment = "awaiting_payment",
  /** Client has initiated funding, e.g. via Open Banking, but we've not received the funds */
  fundingInitiated = "funding_initiated",
  /** Client has provided funds for the transfer */
  funded = "funded",
  /** All conditions have been met */
  approved = "approved",
  /** Payment instructions have been submitted and awaiting further update */
  processing = "processing",
  /** Payment has been sent to the recipient bank */
  paymentSent = "payment_sent",
  /** Payment has been received by the recipient (SWIFT only) */
  paymentConfirmed = "payment_confirmed",
  /** Payment was cancelled by the user, admins or payment processing institution  */
  paymentCancelled = "payment_cancelled",
  /** Payment failed for any reason during processing by payment processing institution */
  paymentFailed = "payment_failed",
  /** If the client doesn't fund the payment by PayByTimeDate then it will be timed_out and cancelled */
  timedOut = "timed_out",
}

export interface IInvoice {
  id?: string;
  _owner?: string;
  contactId?: string;
  contact?: Partial<IRecipient>;
  invoiceNumber: string;
  type: "Receivable" | "Payable";
  status: INVOICE_STATUS;

  reference?: string;
  currency: string;
  /** @deprecated Not used anymore */
  xeroCurrencyRate?: number;
  externalCurrencyRate?: number;
  date: string;
  dueDate: string;

  amountDue: number;
  amountPaid?: number;
  isDiscounted?: boolean;
  totalDiscountAmount?: number;
  subTotalAmount?: number;
  totalTaxAmount?: number;
  totalAmount: number;
  lineAmountTypes?: string;
  lineItems?: IInvoiceLineItem[];
  prebookFee?: number;
  prebookFeePercent?: number;
  fullyPaidOnDate?: string;

  payments?: IInvoicePayment[];

  externalRefs?: {
    sourceSystem?: TExternalSystemType;
    sourceSystemId?: string;
    sourceSystemContactId?: string;
    sourceSystemContactName?: string;
    sourceSystemRepeatingInvoiceId?: string;
  };

  trackingId?: string;
  contractId?: string;
  contractAssignment?: boolean;
  transferId?: string;
  excludeFromRisk?: boolean;

  permissions?: {
    xero?: boolean;
  };
}

export interface IInvoicePayment {
  amount: number;
  transferId?: string;
  transferDate?: string;
  currencyRate?: number;
  sourceSystemId?: string;
  sourceSystemDate?: string;
  sourceSystemWriteToTimestamp?: string;
  sourceSystemLinkToRecord?: string;
}

export enum INVOICE_STATUS {
  draft = "DRAFT",
  submitted = "SUBMITTED",
  deleted = "DELETED",
  authorised = "AUTHORISED",
  paymentScheduled = "PAYMENT SCHEDULED",
  partiallyPaid = "PARTIALLY PAID",
  processingPayment = "PROCESSING PAYMENT",
  paid = "PAID",
  voided = "VOIDED",
  purchaseOrder = "PURCHASE ORDER", // TODO: Reconsider removing this status from interface
  salesOrder = "SALES ORDER",
}

export interface IInvoiceLineItem {
  itemCode?: string; // On Xero and OrderWise
  name: string;
  description: string;
  unitAmount: number;
  lineAmount: number;
  taxAmount?: number;
  quantity: number;
  discountRate?: number;
  discountAmount?: number;
  externalRefs?: {
    sourceSystem?: TExternalSystemType;
    sourceSystemId?: string;
  };
}

export interface ICountry {
  alpha2: string;
  alpha3: string;
  currency: string;
  iban: boolean;
  name: string;
}

export interface IFollowedCurrencyPair {
  id: string;
  sellCurrency: string;
  buyCurrency: string;
  targetRate?: number;
  invertRate?: boolean;
}

export interface IResponse<T = undefined> {
  success: boolean;
  data?: T;
  requestSummary?: any;
  id?: string;
  message?: string;
  exception?: boolean;
}

export enum BUSINESS_TYPE {
  soleTrader = "soleTrader",
  limitedCompany = "limitedCompany",
  limitedLiabilityPartnership = "limitedLiabilityPartnership",
  simplePartnership = "simplePartnership",
  PLC = "PLC",
}

export interface ICompanyRegistrationDetails {
  companyType: string;
  companyCountry: string;
  natureOfBusiness: string;
  natureOfBusinessCode: string;
  entityCurrency: string;
  expectedAnnualTurnover: number;
  expectedFxTurnoverFrom: number;
  expectedFxTurnoverUpperLimit: number;
  countriesSendingMoneyTo: string[];
  purposeOfAccount: string;
  name: string;
  address: IAddress;
  addressCorrespondence: IAddress;
  website: string;
  vat: boolean;
  vatNumber?: string;
  companyRegistrarId?: string;
  ownersRepresentingEntity: IOwner[];
  ownersNotRepresentingEntity?: IOwner[];
  lookupData?: ILookupData[];
}

export interface IEntityDetails
  extends ISystemFields,
    ICompanyRegistrationDetails {
  invoicesAndReceivesPayments?: boolean;
  canProvideInvoicesAndBankStatements?: boolean;
  ddType?: string; // cdd, sdd, edd
  approvedQuarterlyFx?: number;
  creditLimit?: number;
  rating?: number; // 1, 2, 3
  comments?: string;
  /** Indicates if PSC details provided by the user don't match CH records */
  chPscDetailsChanged?: boolean;
  /** If the entity has negotiated on a lower exchange fee rate then it would be presented here */
  conversionFeeRateOverride?: number;
  ddApprovedDate?: string; // Due Diligence Approval Date
  ddRejectDate?: string; // Due Diligence Rejection Date
  ddNextDate?: string; // Next Due Diligence Due Date
  consumerDutyApplicable?: boolean;
}

export interface IAddress {
  addressLine1: string;
  addressLine2?: string; //Sometimes referred to as "Premises", e.g. "Lower Ground Floor" or "New Burlington House"
  postalCode: string;
  city: string;
  country: string; //alpha2 code
  stateOrProvince?: string; //Used for some countries
}

export interface IOwner {
  name: string;
  email: string;
  dob: string;
  isUser: boolean; //Indicates if this owner is the user registering the company
  address?: IAddress; //Pulled in from the Director's data when possible
  businessName?: string; //Used for partnerships
}

export interface ILookupData {
  source: string;
  path?: string;
  data: any;
}

export type PrebookStatuses =
  | "awaiting_deposit"
  | "deposit_overdue"
  | "ready_to_use"
  | "ready_to_use && numberOfTransfers"
  | "used"
  | "cancelled";

export type TransferStatuses =
  | "awaiting_payment"
  | "processing"
  | "payment_paused"
  | "payment_sent"
  | "payment_confirmed";

export interface ICheck {
  id: string;
  _manual: boolean;
  _onboardingId: string;
  _resultsUrl?: string;
  _source: string;
  _status: string;
  _type: string;
  description?: string;
  runType: string;
}

export interface IBalance {
  id: string;
  sourceSystemId: string;
  currency: string;
  /** Currently represent the actual amount in Currency Cloud. Going forward, this cannot be presented to the user. */
  amount: number;
  /** Populated but not used */
  createdAt: string;
  /** Populated but not used */
  updatedAt: string;
  /** Available amount to the user, coming from the Ledger. Going forward this will be the amount presented to the user. */
  availableAmount?: number;
  availableAmountUpdatedAt?: string;
  actualAmount?: number;
}

export interface ISanctionResult {
  id: string;
  success: boolean;
  clear: boolean;
  /** If FALSE, then will be set to TRUE after a successful review */
  resolved: boolean;
  matchStatus?: string;
  hasHits?: boolean;
  numberOfHits?: number;
  result?: any;
  error?: string;
  exception?: boolean;
  comments?: string;
}

export interface ISanctionResultUpdate {
  comments: string;
  resolved?: boolean;
}

interface IRecipientOnboardingSanctionDoc extends ISanctionResult {
  id: string;
  clear: boolean;
}

export interface ICaEvent {
  id: string;
  _event: string;
  _eventSub: "update";
  _messageType: "sanctionChecks";
  _notificationType: string;
  search_id: string;
}

export interface ICaMonitoredSearchEvent extends ICaEvent {
  new: string[];
  updated: string[];
  removed: string[];
  is_suspended: boolean;
  result?: any;
}

interface IRecipientOnboardingSanctionUpdateDoc
  extends ICaMonitoredSearchEvent {
  resolved?: boolean;
  comments?: string;
}

export type IRecipientOnboardingDoc =
  | IRecipientOnboardingSanctionDoc
  | IRecipientOnboardingSanctionUpdateDoc;

// typeguard for IRecipientOnboardingSanctionDoc
export const isSanctionSearchDoc = (
  doc: IRecipientOnboardingDoc
): doc is IRecipientOnboardingSanctionDoc =>
  (doc as IRecipientOnboardingSanctionDoc).clear !== undefined;

export const isSanctionUpdateDoc = (
  doc: IRecipientOnboardingDoc
): doc is IRecipientOnboardingSanctionUpdateDoc =>
  (doc as IRecipientOnboardingSanctionUpdateDoc)._eventSub === "update";

export enum CASHFLOW_TYPE {
  /** Used only when we add custom cashflows to our database */
  payable = "payable",
  /** Used only when we add custom cashflows to our database */
  receivable = "receivable",
  prebook = "prebook",
  externalHedge = "externalHedge",
  externalBalance = "externalBalance",
  balance = "balance",
  salesOrder = "salesOrder",
  purchaseOrder = "purchaseOrder",
  payableInvoice = "payableInvoice",
  receivableInvoice = "receivableInvoice",
}

export interface ICustomCashflowDefinition {
  id: string;
  name: string;
  type: CASHFLOW_TYPE.receivable | CASHFLOW_TYPE.payable;
}

type TCashflowsShareSystem = "google" | "microsoftFileUpload";

export interface IEntityCashflowsSettings {
  customCashflowDefinitions?: ICustomCashflowDefinition[];
  /** Used for loading part of the cashflows data from CSV */
  fileShareUrl?: string;
  fileShareSystem?: TCashflowsShareSystem;
  /** Used to net the cashflows at risk by period e.g. month or week */
  cashflowsAtRiskNettingPeriod?: "month" | "week";
}
