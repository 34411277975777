import { FC, useState, useEffect } from "react";
import { useParams } from "react-router";
import {
  Table,
  Typography,
  Form,
  Input,
  Button,
  Select,
  Descriptions,
  Badge,
  Tag,
  Tooltip,
  message,
  Checkbox,
} from "antd";
import firebase from "firebase/app";
import dayjs from "dayjs";

import "./OnboardingDetails.css";
import {
  toCurrencyNumber,
  asyncForEach,
  getUniqueDecreasingNumber,
  openDoc,
  openQuery,
  openQueryWithTimestamp,
  TCustomRouteComponentProps,
} from "utils";
import "antd/dist/antd.css";

import axios from "axios";
import { Link } from "react-router-dom";
import { Firebase } from "services";
import EditCheck from "./components/EditCheck";
import UpdateDdInfo from "./components/UpdateDdInfo";
import { ICheck, IEntityCashflowsSettings } from "types";
import { uploadCheckFile } from "services/firebase";
import { AxiosPrivateFirebaseInstance } from "settings";
import AddCheck from "./components/AddCheck";
import EditOnboardingRecord from "./components/EditOnboardingRecord";

import { JsonEditor as Editor } from "jsoneditor-react";
import "jsoneditor-react/es/editor.min.css";
import ace from "brace";
import "brace/mode/json";
import "brace/theme/github";
import db from "services/firestore";
import CreateOnboardingRecord from "./components/CreateOnboardingRecord";
import ProfitAndLoss from "../../components/ProfitAndLoss/ProfitAndLoss";
import { getIntegrationColumns } from "components/IntegrationColumns/IntegrationColumns";
import { DATE_FORMAT } from "settings/variables";
import EntityUsersAndInvites from "components/EntityUsersAndInvites/EntityUsersAndInvites";

const { Option } = Select;

const subscribeToEntityCashflowsSettings = (entityId, callback) => {
  try {
    const unsubscribe = db
      .collection("entities")
      .doc(entityId)
      .collection("docs")
      .doc("cashflowsSettings")
      .onSnapshot((doc) => callback(openDoc(doc)));

    return unsubscribe;
  } catch (error) {
    console.log(error);
  }
};

const getEntity = async (entityId) => {
  try {
    const entity = await db
      .collection("entities")
      .doc(entityId)
      .get()
      .then((doc) => openDoc(doc));

    return entity;
  } catch (error) {
    console.log(error);
  }
};

const subscribeToEntityDetails = (entityId, callback) => {
  try {
    const subscribe = db
      .collection("entities")
      .doc(entityId)
      .collection("onboarding")
      .doc("companyDetails")
      .onSnapshot((doc) => callback(openDoc(doc)));

    return subscribe;
  } catch (error) {
    console.log(error);
  }
};

const subscribeToUserDetails = (userId, callback) => {
  try {
    const unsubscribe = db
      .collection("users")
      .doc(userId)
      .onSnapshot((doc) => callback(openDoc(doc)));

    return unsubscribe;
  } catch (error) {
    console.log(error);
  }
};

const subscribeToOnboardingItems = (entityId, callback) => {
  const unsubscribe = db
    .collection("entities")
    .doc(entityId)
    .collection("onboarding")
    .onSnapshot((query) => callback(openQuery(query)));

  return unsubscribe;
};

const subscribeToChecks = (entityId: string, callback) => {
  try {
    const subscribe = db
      .collection("entities")
      .doc(entityId)
      .collection("checks")
      .onSnapshot((query) => callback(openQuery(query)));

    return subscribe;
  } catch (error) {
    console.log(error);
  }
};

const subscribeToOnboardingFiles = (entityId: string, callback) => {
  try {
    return db
      .collection("entities")
      .doc(entityId)
      .collection("onboardingFiles")
      .onSnapshot((query) =>
        callback(openQueryWithTimestamp(query, ["_created"]))
      );
  } catch (error) {
    console.log(error);
  }
};

const { Title, Text } = Typography;
const { TextArea } = Input;

const OnboardingDetails: FC<TCustomRouteComponentProps> = ({ allowWrite }) => {
  const [entity, setEntity] = useState(null);
  const [entityDetails, setEntityDetails] = useState(null);
  const [onboardingItems, setOnboardingItems] = useState([]);
  const [checks, setChecks] = useState([]);
  const [activeOnboardingForCheck, setActiveOnboardingForCheck] =
    useState(null);
  const [user, setUser] = useState(null);
  const [onboardingForEdit, setOnboardingForEdit] = useState(null);
  const [onboardingToCreate, setOnboardingToCreate] = useState(null);
  const [integrations, setIntegrations] = useState(
    [] as Firebase.IIntegrationBase[]
  );

  const [isDrawer, setIsDrawer] = useState({ visible: false });
  const [entityFiles, setEntityFiles] = useState([]);

  const approveRejectForm = Form.useForm()[0];
  const descriptionForm = Form.useForm()[0];

  const hasXeroIntegration = !!integrations.find(
    (integration) =>
      integration.system === "xero" && integration.status === "connected"
  );

  const [companyJsonValue, setCompanyJsonValue] = useState({
    companyType: "limitedCompany",
    name: "Freight Fighters",
    entityCurrency: "GBP",
    companyCountry: "gb",
    expectedAnnualTurnover: 300000,
    expectedFxTurnoverUpperLimit: 50000,
    enabled: false,
    status: "onboardingStep4Completed",
  });
  const [companyDetailsJsonValue, setCompanyDetailsJsonValue] = useState({
    name: "Freight Fighters",
    natureOfBusinessCode: "01160",
    vat: true,
    vatNumber: "GB177596254",
    natureOfBusiness: "Growing of fibre crops",
    purposeOfAccount: "Invoice payments for imports",
    countriesSendingMoneyTo: ["fi", "fr", "de", "us"],
    creditLimit: 150000,
    approvedQuarterlyFx: 200000,
    ddType: "cdd",
    rating: 1,
    addressCorrespondence: {
      addressLine1: "2 Marylebone Court",
      postalCode: "WN1 2NX",
      country: "gb",
      stateOrProvince: "Lancashire",
      city: "WIGAN",
    },
    address: {
      stateOrProvince: "Lancashire",
      postalCode: "WN1 2NX",
      city: "WIGAN",
      country: "gb",
      addressLine1: "2 Marylebone Court",
    },
  });

  useEffect(() => {
    if (entity && entityDetails) {
      approveRejectForm.setFieldsValue({ ...entityDetails });
    }
  }, [approveRejectForm, entityDetails, entity]);

  const onClose = () => {
    setIsDrawer({
      visible: false,
    });
  };

  // @ts-ignore
  const { entity_id } = useParams();

  useEffect(() => {
    if (entity_id) {
      const subscribeOnboardingFiles = subscribeToOnboardingFiles(
        entity_id,
        (data) => {
          setEntityFiles(data);
        }
      );

      return () => {
        if (subscribeOnboardingFiles) {
          subscribeOnboardingFiles();
        }
      };
    }
  }, [isDrawer.visible, entity_id]);

  useEffect(() => {
    if (entity_id) {
      getEntity(entity_id).then((res) => {
        if (res) {
          setEntity(res);
        }
      });

      const subscribeEntityDetails = subscribeToEntityDetails(
        entity_id,
        setEntityDetails
      );

      const unsubscribeOnboardingItems = subscribeToOnboardingItems(
        entity_id,
        (data) => setOnboardingItems(data)
      );

      const subscribeChecks = subscribeToChecks(entity_id, setChecks);

      return () => {
        subscribeEntityDetails && subscribeEntityDetails();
        subscribeChecks && subscribeChecks();
        unsubscribeOnboardingItems && unsubscribeOnboardingItems();
      };
    }
  }, [entity_id]);

  useEffect(() => {
    if (activeOnboardingForCheck) {
      setIsDrawer({
        visible: true,
      });
    } else {
      setIsDrawer({
        visible: false,
      });
    }
  }, [activeOnboardingForCheck]);

  useEffect(() => {
    if (entity) {
      const unsubscribe = subscribeToUserDetails(entity._createdBy, (data) =>
        setUser(data)
      );

      return () => {
        if (unsubscribe) {
          unsubscribe();
        }
      };
    }
  }, [entity]);

  useEffect(() => {
    if (entity && !entity.onboarded) {
      Firebase.getIntegrations(entity.id).then((data) => setIntegrations(data));
    }
  }, [entity]);

  const getOnboardingChecks = (onboardingId) =>
    checks.filter((item) => item._onboardingId === onboardingId);

  const [cashflowsSettings, setCashflowsSettings] =
    useState<IEntityCashflowsSettings | null>(null);

  useEffect(() => {
    if (entity) {
      const unsubscribe = subscribeToEntityCashflowsSettings(
        entity.id,
        (data) => setCashflowsSettings(data)
      );
      return () => {
        if (unsubscribe) {
          unsubscribe();
        }
      };
    }
  }, [entity]);

  const cashflowsSettingsForm = Form.useForm()[0];

  useEffect(() => {
    if (cashflowsSettings) {
      cashflowsSettingsForm.setFieldsValue({ ...cashflowsSettings });
    }
  }, [cashflowsSettingsForm, cashflowsSettings]);

  const checksColumns = [
    {
      title: "Created",
      dataIndex: "_created",
      key: "_created",
      // render: (date) => dayjs(date).format("DD MMM YYYY HH:mm:ss"),
      render: (date) =>
        date && dayjs(date.seconds * 1000).format("DD MMM YYYY HH:mm:ss"),
    },
    {
      title: "Type",
      dataIndex: "_type",
      key: "_type",
    },
    {
      title: "Source",
      dataIndex: "_source",
      key: "_source",
      render: (text, record) => {
        const isOnfidoCheck = text === "onfido" && record._type === "check";

        return (
          <div>
            {isOnfidoCheck ? (
              <span style={{ marginRight: 8 }}>
                <a href={record.results_uri} target="_blank" rel="noreferrer">
                  {text}
                </a>
              </span>
            ) : (
              text
            )}
          </div>
        );
      },
    },
    {
      title: "Desc / Link",
      dataIndex: "name",
      key: "name",
      render: (text, record) => {
        const isName = record.name !== null && record.name !== undefined;
        const isUrl =
          record._resultsUrl !== null &&
          record._resultsUrl !== undefined &&
          record._resultsUrl !== "";
        const isManual =
          record._manual !== null && record._manual !== undefined;

        return (
          <>
            {isName && text}
            {!isName && isUrl && isManual && (
              <span style={{ marginRight: 8 }}>
                <a href={record._resultsUrl} target="_blank" rel="noreferrer">
                  Link
                </a>
              </span>
            )}
          </>
        );
      },
    },
    {
      title: "Status",
      dataIndex: "_status",
      key: "_status",
      render: (text, record) => {
        const isClear = text && text.toLowerCase() === "clear";
        const isConsider = text && text.toLowerCase() === "consider";

        return (
          <>
            {isClear && <Tag color="green">CLEAR</Tag>}
            {isConsider && <Tag color="red">CONSIDER</Tag>}
            {!isClear && !isConsider && text}
          </>
        );
      },
    },
    {
      title: "ID",
      dataIndex: "id",
      key: "id",
    },
    {
      title: "Files",
      dataIndex: "id",
      key: "id",
      render: (text, record) => {
        const checkFiles = entityFiles.filter(
          (item) => item.parentId === record.id
        );

        return (
          <div
            style={{
              display: "flex",
              flexWrap: "wrap",
            }}
          >
            {checkFiles.map((item) => {
              return (
                <Tag
                  style={{
                    margin: 4,
                  }}
                >
                  <a href={item.location} target="_blank" rel="noreferrer">
                    {item.name}
                  </a>
                </Tag>
              );
            })}
          </div>
        );
      },
    },
    {
      title: "",
      dataIndex: "",
      key: "check",
      render: (text, record) => {
        const hasExternalResults =
          record._source === "onfido" && record._resultsUrl;
        return (
          <>
            {allowWrite && hasExternalResults && (
              <Button
                type="default"
                onClick={() => {
                  updateRecordFrom3rdParty(record);
                }}
              >
                Update
              </Button>
            )}
          </>
        );
      },
    },
    ...(allowWrite
      ? [
          {
            title: "",
            dataIndex: "",
            key: "edit",
            render: (text, record) => {
              return (
                <>
                  <Button onClick={() => setCheckForEdit(record)}>Edit</Button>
                </>
              );
            },
          },
        ]
      : []),
  ];

  const integrationsColumns = getIntegrationColumns(entity_id);

  const updateRecordFrom3rdParty = async (record) => {
    if (record._source === "onfido" && record._resultsUrl) {
      const token = await Firebase.auth.currentUser.getIdToken();
      const headers = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };
      await axios.get(
        `${process.env.REACT_APP_CLOUD_FUNCTIONS_BASE_URL}/admin_actions/update_results/${entity_id}/${record.id}`,
        headers
      );
    }
  };

  interface ICheckInput {
    source: string;
    status: string;
    type: string;
    description?: string;
    runType: string;
    onboardingId: string;
    resultsUrl?: string;
    attachments?: IFileDetails[];
  }
  interface IFileDetails {
    id?: string; //Populate if you're updating the record, otherwise it's a new record
    name: string;
    location: string;
    linkedTo: string;
    type: "check" | "onboarding";
    description?: string;
    parentId?: string;
    removed?: boolean;
  }

  const addCheck = async (values, filesForUpload) => {
    try {
      const newCheck: ICheckInput = {
        source: values._source,
        status: values._status,
        type: "check",
        description: values.description || "",
        runType: values.runType,
        onboardingId: activeOnboardingForCheck.id,
        resultsUrl: values._resultsUrl || "",
        attachments: [],
      };

      if (filesForUpload.length) {
        const newCheckId = getUniqueDecreasingNumber();

        await asyncForEach(filesForUpload, async (file) => {
          const downloadURL = await uploadCheckFile({
            entityId: entity_id,
            checkId: newCheckId,
            fileToUpload: file,
          });

          const fileDetails: IFileDetails = {
            name: file.name,
            location: downloadURL,
            linkedTo: `/entities/${entity_id}/onboarding/${activeOnboardingForCheck.id}`,
            type: "check",
          };

          newCheck.attachments.push(fileDetails);
        });

        await AxiosPrivateFirebaseInstance.post(
          `/admin_actions/checks/${entity_id}/${newCheckId}`,
          newCheck
        );
      } else {
        await AxiosPrivateFirebaseInstance.post(
          `/admin_actions/checks/${entity_id}`,
          newCheck
        );
      }

      message.success("Check created successfully");
    } catch (error) {
      message.error(error.message);
    }
  };

  const approveOnboarding = async () => {
    const token = await Firebase.auth.currentUser.getIdToken();
    const headers = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    return axios.post(
      `${process.env.REACT_APP_CLOUD_FUNCTIONS_BASE_URL}/admin_actions/onboarding/${entity_id}/approve`,
      null,
      headers
    );
  };

  const [onboardingApprovedClicked, setOnboardingApprovedClicked] =
    useState(false);

  const approveOnboardingClick = async () => {
    setOnboardingApprovedClicked(true);
    message.info("Processing onboarding approval - This may take few seconds");
    const values = approveRejectForm.getFieldsValue(true);

    try {
      await Firebase.updateEntityOnboardingCompanyDetails(entity_id, values);

      const { data } = await approveOnboarding();

      if (data?.success) {
        getEntity(entity_id).then((res) => {
          res && setEntity(res);
        });
        message.success("Onboarding approved.");
      } else {
        message.error(data.message);
      }
    } catch (error) {
      message.error(error.message);
    } finally {
      setOnboardingApprovedClicked(false);
    }
  };

  const rejectOnboarding = async () => {
    const token = await Firebase.auth.currentUser.getIdToken();
    const headers = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    await axios
      .post(
        `${process.env.REACT_APP_CLOUD_FUNCTIONS_BASE_URL}/admin_actions/onboarding/${entity_id}/reject`,
        null,
        headers
      )
      .then((res) =>
        getEntity(entity_id).then((res) => {
          res && setEntity(res);
        })
      );
  };

  const quickRegisterCompany = async (companyValues, companyDetailsValues) => {
    try {
      await db
        .collection("entities")
        .doc(entity.id)
        .set({ ...companyValues }, { merge: true });

      await db
        .collection("entities")
        .doc(entity.id)
        .collection("onboarding")
        .doc("companyDetails")
        .set({ ...companyDetailsValues }, { merge: true });

      message.success("Entity updated with quick-details.");
    } catch (error) {
      console.log(error, "error");
    }
  };

  const onboardingItemsColumns = [
    {
      title: "Created",
      dataIndex: "_created",
      key: "_created",
      // render: (date) => dayjs(date).format("DD MMM YYYY HH:mm:ss"),
      render: (date) =>
        date && dayjs(date.seconds * 1000).format("DD MMM YYYY HH:mm:ss"),
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      render: (text, record) => {
        const isUser = record.isUser;
        const isPSC = record.isPsc;
        const isCoRep = record.isRepresentingEntity;

        return (
          <div>
            <span style={{ marginRight: 8 }}>{text}</span>

            {isUser && <Tag color="geekblue">user</Tag>}
            {isCoRep && <Tag color="volcano">co rep</Tag>}
            {isPSC && <Tag color="red">PSC</Tag>}
            {isUser && user && user.name?.trim() !== text?.trim() && (
              <span style={{ fontStyle: "italic", backgroundColor: "yellow" }}>
                <br />
                <span style={{ color: "red" }}>{user.name}</span>
                {" <--"} User's name is different
              </span>
            )}
          </div>
        );
      },
    },
    {
      title: "Role",
      dataIndex: "role",
      key: "role",
      width: "150px",
      render: (text, record) => {
        const role = record.id === "companyDetails" ? "company" : text;
        const tooltipText =
          record.pscNaturesOfControl &&
          record.pscNaturesOfControl.map((item) => item + " | ");
        return (
          <>
            <Tooltip title={tooltipText}>{role}</Tooltip>
          </>
        );
      },
    },
    {
      title: "Status",
      render: (text, record) => {
        const reqVerification =
          record.requiresVerification || record.id === "companyDetails";
        let isConsider = false;
        let isUnfinished = false;
        const checks = getOnboardingChecks(record.id);
        checks.map((check) => {
          if (check._status === "consider") isConsider = true;
          if (check._status !== "consider" && check._status !== "clear")
            isUnfinished = true;
          return undefined;
        });
        enum STATUS {
          not_required,
          unfinished,
          not_started,
          consider,
          clear,
        }
        let status: STATUS = STATUS.not_required;
        if (reqVerification) {
          if (checks.length === 0) status = STATUS.not_started;
          else if (isUnfinished) status = STATUS.unfinished;
          else if (isConsider) status = STATUS.consider;
          else status = STATUS.clear;
        }
        const addedByUser = record.addedByUser;
        const removedByUser = record.removedByUser;

        return (
          <>
            {status === STATUS.not_required && <Tag>Not Required</Tag>}
            {status === STATUS.not_started && (
              <Tag color="cyan">Not Started</Tag>
            )}
            {status === STATUS.unfinished && (
              <>
                <Tag color="gold">Unfinished</Tag>({checks.length})
              </>
            )}
            {status === STATUS.consider && (
              <>
                <Tag color="red">CONSIDER</Tag>({checks.length})
              </>
            )}
            {status === STATUS.clear && (
              <>
                <Tag color="green">CLEAR</Tag>({checks.length})
              </>
            )}
            {addedByUser && (
              <>
                <br />
                <Tag color="warning">Added by user</Tag>
              </>
            )}
            {removedByUser && (
              <>
                <br />
                <Tag color="warning">Removed by user</Tag>
              </>
            )}
          </>
        );
      },
    },
    {
      title: "ID",
      dataIndex: "id",
      key: "id",
    },
    {
      title: "",
      dataIndex: "",
      key: "check",
      render: (text, record) =>
        allowWrite ? (
          <Button
            type="primary"
            onClick={() => {
              setActiveOnboardingForCheck(record);
            }}
          >
            + Check
          </Button>
        ) : null,
    },
    ...(allowWrite
      ? [
          {
            title: "",
            dataIndex: "",
            key: "edit",
            render: (text, record) => (
              <Button
                type="primary"
                onClick={() => {
                  setOnboardingForEdit(record);
                }}
              >
                Edit
              </Button>
            ),
          },
        ]
      : []),
  ];

  const [ddDatesForEdit, setDdDatesForEdit] = useState<{
    ddApprovedDate: string;
    ddNextDate: string;
  } | null>(null);

  const updateDdDates = async (ddApprovedDate: string, ddNextDate: string) => {
    await db
      .collection("entities")
      .doc(entity_id)
      .collection("onboarding")
      .doc("companyDetails")
      .update({
        ddApprovedDate,
        ddNextDate,
      });
  };

  const [checkForEdit, setCheckForEdit] = useState<ICheck | null>(null);

  const updateCheck = async (check, values, files) => {
    await db
      .collection("entities")
      .doc(entity_id)
      .collection("checks")
      .doc(check.id)
      .update({
        ...values,
      });

    if (!!files.length) {
      await asyncForEach(files, async (item) => {
        if (item.forUpload) {
          const { forUpload, ...itemForUpload } = item;

          const downloadUrl = await Firebase.uploadCheckFile({
            checkId: check.id,
            entityId: entity_id,
            fileToUpload: itemForUpload.value,
          });

          const newId = getUniqueDecreasingNumber();

          await db
            .collection("entities")
            .doc(entity_id)
            .collection("onboardingFiles")
            .doc(newId)
            .set({
              name: item.name,
              location: downloadUrl,
              linkedTo: `/entities/${entity_id}/onboarding/${check._onboardingId}`,
              _created: firebase.firestore.FieldValue.serverTimestamp(),
              type: "check",
              parentId: check.id,
            });
        } else if (item.forDelete) {
          await Firebase.removeCheckFile({
            checkId: check.id,
            entityId: entity_id,
            filename: item.name,
          });

          await db
            .collection("entities")
            .doc(entity_id)
            .collection("onboardingFiles")
            .doc(item.id)
            .delete();
        }
      });
    }
  };

  return (
    <div>
      <>
        <UpdateDdInfo
          isVisible={!!ddDatesForEdit}
          onClose={() => setDdDatesForEdit(null)}
          ddApprovedDate={ddDatesForEdit?.ddApprovedDate}
          ddNextDate={ddDatesForEdit?.ddNextDate}
          updateDdDates={updateDdDates}
        />

        <EditCheck
          isVisible={!!checkForEdit}
          onClose={() => setCheckForEdit(null)}
          check={checkForEdit}
          entityId={entity_id}
          updateCheck={updateCheck}
        />

        <AddCheck
          addCheck={addCheck}
          isVisible={isDrawer.visible}
          onClose={onClose}
          title={`Register a new check on ${activeOnboardingForCheck?.name}`}
        />

        <EditOnboardingRecord
          entityId={entity_id}
          onboardingItem={onboardingForEdit}
          onClose={() => setOnboardingForEdit(null)}
          isVisible={!!onboardingForEdit}
        />

        <CreateOnboardingRecord
          entityId={entity_id}
          onClose={() => setOnboardingToCreate(null)}
          isVisible={!!onboardingToCreate}
        />
      </>

      {entity && !entityDetails && (
        <>
          <Title>
            {entity.name}

            {allowWrite && (
              <Button
                danger
                style={{ margin: "8px 0 0 0", float: "right" }}
                onClick={async () => {
                  await Firebase.actOnBehalfOfEntity(entity_id);
                }}
              >
                Act on behalf of Entity
              </Button>
            )}
          </Title>
          <i>
            <Text>
              {user && `Operated by ${user?.name} (${user.email}) (${user.id})`}
            </Text>
          </i>
          <span
            style={{ float: "right", marginRight: "20px", textAlign: "right" }}
          >
            {entity && (
              <Link to={`/app/entity-detail/${entity_id}`}>Entity Details</Link>
            )}
          </span>
          <Title level={2}>Entity has not started registration</Title>
        </>
      )}

      {entity &&
        entity.status === "onboardingNotStarted" &&
        !entityDetails &&
        process.env.REACT_APP_ENVIRONMENT_NAME !== "PROD" && (
          <>
            <Title level={2}>
              Here you can push through a registration (in non-prod environment)
            </Title>
            <Form
              layout="vertical"
              onFinish={async () => {
                await quickRegisterCompany(
                  companyJsonValue,
                  companyDetailsJsonValue
                );
              }}
            >
              <table style={{ width: "100%" }}>
                <tr>
                  <td>
                    <Form.Item label="Entity Data">
                      <Editor
                        ace={ace}
                        theme="ace/theme/github"
                        value={companyJsonValue}
                        mode="code"
                        onChange={(data) => {
                          setCompanyJsonValue(data);
                        }}
                      />
                    </Form.Item>
                  </td>
                  <td>
                    <Form.Item label="Entity Details">
                      <Editor
                        ace={ace}
                        theme="ace/theme/github"
                        height="30"
                        value={companyDetailsJsonValue}
                        mode="code"
                        onChange={(data) => {
                          setCompanyDetailsJsonValue(data);
                        }}
                      />
                    </Form.Item>
                  </td>
                </tr>
              </table>

              {allowWrite && (
                <Button htmlType="submit" type="primary">
                  Submit
                </Button>
              )}
            </Form>
          </>
        )}

      {entity && entityDetails ? (
        <>
          <Title>
            {entity.name}

            {allowWrite && (
              <Button
                danger
                style={{ margin: "8px 0 0 0", float: "right" }}
                onClick={async () => {
                  await Firebase.actOnBehalfOfEntity(entity_id);
                }}
              >
                Act on behalf of Entity
              </Button>
            )}
          </Title>
          <i>
            <Text>
              {entityDetails.companyRegistrarId &&
                `(${entityDetails.companyRegistrarId}) - `}
              {user && `Operated by ${user?.name} (${user.email}) (${user.id})`}
            </Text>
          </i>
          <span
            style={{ float: "right", marginRight: "20px", textAlign: "right" }}
          >
            {entityDetails.ddApprovedDate && (
              <span>
                DD completed on{" "}
                {dayjs(entityDetails.ddApprovedDate, "YYYY-MM-DD").format(
                  DATE_FORMAT
                )}
                {" - "}
              </span>
            )}
            {entityDetails.ddNextDate && (
              <span>
                Next DD is due on{" "}
                {dayjs(entityDetails.ddNextDate, "YYYY-MM-DD").format(
                  DATE_FORMAT
                )}
                <br />
              </span>
            )}
            {entity && (
              <>
                <Link to={`/app/entity-detail/${entity_id}`}>
                  Entity Details
                </Link>
                {" - "}
                <Button
                  style={{ padding: "0px 0 0 0" }}
                  type="link"
                  onClick={() =>
                    setDdDatesForEdit({
                      ddApprovedDate: entityDetails.ddApprovedDate,
                      ddNextDate: entityDetails.ddNextDate,
                    })
                  }
                >
                  Update DD Dates
                </Button>
              </>
            )}
          </span>
          <Form
            form={descriptionForm}
            initialValues={{
              ddType: entityDetails.ddType,
              creditLimit: entityDetails.creditLimit,
              shouldShowReports: !!entity.shouldShowReports,
              consumerDutyApplicable: !!entityDetails.consumerDutyApplicable,
              subStatusAfterOnboarding: entity.subStatusAfterOnboarding
                ? entity.subStatusAfterOnboarding
                : entity?.enabled && "live",
              approvedQuarterlyFx: entityDetails.approvedQuarterlyFx,
              conversionFeeRateOverride:
                entityDetails.conversionFeeRateOverride,
            }}
            onFinish={async (values) => {
              try {
                await Firebase.updateEntityOnboardingCompanyDetails(
                  entity_id,
                  values
                );
                message.success("Updated entity onboarding company details.");
              } catch (error) {
                message.error(error.message);
              }
            }}
          >
            <Descriptions bordered>
              <Descriptions.Item label="Nature of Business">
                {entityDetails.natureOfBusiness}
              </Descriptions.Item>

              <Descriptions.Item label="VAT">
                {entityDetails.vat ? entityDetails.vatNumber : "NO"}
              </Descriptions.Item>
              <Descriptions.Item label="Type of Due Diligence">
                <Form.Item name="ddType" style={{ marginBottom: "0px" }}>
                  <Select placeholder="Type of Due Diligence">
                    <Option value="cdd">Client DD</Option>
                    <Option value="sdd">Simplified DD</Option>
                    <Option value="edd">Enhanced DD</Option>
                  </Select>
                </Form.Item>
              </Descriptions.Item>

              <Descriptions.Item label="Purpose of Account">
                {entityDetails.purposeOfAccount}
              </Descriptions.Item>
              <Descriptions.Item
                label="Expected Annual Turnover"
                style={{ whiteSpace: "nowrap" }}
              >
                £{toCurrencyNumber(entity.expectedAnnualTurnover, 0)}
              </Descriptions.Item>
              <Descriptions.Item label="Credit Limit">
                <Form.Item
                  name="creditLimit"
                  shouldUpdate
                  style={{ marginBottom: "0px" }}
                >
                  <Input prefix="£" placeholder="Credit Limit" />
                </Form.Item>
              </Descriptions.Item>

              <Descriptions.Item label="Countries sending money to">
                {entityDetails.countriesSendingMoneyTo.map(
                  (country) => `${country}, `
                )}
              </Descriptions.Item>
              <Descriptions.Item
                label="Expected Monthly FX"
                style={{ whiteSpace: "nowrap" }}
              >
                £{toCurrencyNumber(entity.expectedFxTurnoverUpperLimit, 0)}
              </Descriptions.Item>
              <Descriptions.Item label="Approved Quarterly Max">
                <Form.Item
                  name="approvedQuarterlyFx"
                  style={{ marginBottom: "0px" }}
                >
                  <Input prefix="£" placeholder="Approved Quarterly FX value" />
                </Form.Item>
              </Descriptions.Item>

              <Descriptions.Item label="URL or Social Media">
                {entity.website}
              </Descriptions.Item>
              <Descriptions.Item label="Reports enabled">
                <Form.Item
                  name="shouldShowReports"
                  style={{ marginBottom: "0px" }}
                  valuePropName="checked"
                >
                  <Checkbox />
                </Form.Item>
              </Descriptions.Item>
              <Descriptions.Item label="Conversion Fee Rate Override">
                <Form.Item
                  name="conversionFeeRateOverride"
                  shouldUpdate
                  style={{ marginBottom: "0px" }}
                >
                  <Input placeholder="Default is 0.004" />
                </Form.Item>
              </Descriptions.Item>

              <Descriptions.Item
                label={
                  <>
                    Status {entity.enabled && <Tag color="green">ENABLED</Tag>}
                    {!entity.enabled && entity.status === "onboarded" && (
                      <Tag color="red">DISABLED</Tag>
                    )}
                  </>
                }
              >
                {entity.enabled === false &&
                  entity.status === "onboardingStep4Completed" && (
                    <Badge status="processing" text="Onboarding Review" />
                  )}
                {entity.onboarded === true && (
                  <Badge status="success" text="Onboarding Approved" />
                )}
                {entity.enabled === false && entity.onboarded === false && (
                  <Badge status="error" text="Onboarding Rejected" />
                )}
                {entity.enabled === false &&
                  entity.onboarded === false &&
                  entity.status !== "onboardingStep4Completed" && (
                    <Badge status="warning" text="Registration Not Completed" />
                  )}
              </Descriptions.Item>

              <Descriptions.Item label="Consumer Duty applicable" span={2}>
                <Form.Item
                  name="consumerDutyApplicable"
                  style={{ marginBottom: "0px" }}
                  valuePropName="checked"
                >
                  <Checkbox />
                </Form.Item>
              </Descriptions.Item>

              <Descriptions.Item label="Sub-Status">
                <Form.Item
                  name="subStatusAfterOnboarding"
                  shouldUpdate
                  style={{ marginBottom: "0px" }}
                >
                  <Select placeholder="Live = Enabled">
                    <Option value="live">Live</Option>
                    <Option value="dormant">Dormant</Option>
                    <Option value="exited">Exited</Option>
                    <Option value="onHold">On hold (Sanction Review)</Option>
                  </Select>
                </Form.Item>
              </Descriptions.Item>

              <Descriptions.Item>
                {allowWrite && (
                  <Button
                    type="default"
                    style={{ float: "right" }}
                    htmlType="submit"
                  >
                    Update
                  </Button>
                )}
              </Descriptions.Item>
            </Descriptions>
          </Form>
        </>
      ) : null}

      {entity && entityDetails && (
        <Table
          title={() => (
            <span>
              <Title level={5}>
                Onboarding Items for Review
                {allowWrite && (
                  <Button
                    type="primary"
                    style={{ margin: "8px 0 0 0", float: "right" }}
                    onClick={() => setOnboardingToCreate({})}
                  >
                    Create an Onboarding record
                  </Button>
                )}
              </Title>
            </span>
          )}
          columns={onboardingItemsColumns}
          rowClassName={(record) => (record.isDeprecated ? "deprecated" : "")}
          // need keys for correct expansions
          dataSource={onboardingItems.map((item, index) => ({
            ...item,
            key: index,
          }))}
          expandable={{
            expandedRowRender: (record) => {
              return (
                <Table
                  columns={checksColumns}
                  dataSource={getOnboardingChecks(record.id)}
                />
              );
            },
            rowExpandable: (record) =>
              record.requiresVerification === true ||
              record.id === "companyDetails" ||
              getOnboardingChecks(record.id).length > 0,
          }}
        />
      )}

      {!!entityFiles.length && (
        <Table
          title={() => (
            <span style={{ display: "flex" }}>
              <Title level={5}>Entity files</Title>
            </span>
          )}
          columns={[
            { title: "ID", dataIndex: "id", key: "id" },
            { title: "Name", dataIndex: "name", key: "name" },
            {
              title: "Created",
              dataIndex: "_created",
              key: "_created",
              render: (text, record) => {
                return dayjs(text).format();
              },
            },
            { title: "Type", dataIndex: "type", key: "type" },
            {
              title: "URL",
              dataIndex: "url",
              key: "url",
              render: (text, record) => {
                return (
                  <Typography.Link
                    href={record.location}
                    rel="noreferrer"
                    target="_blank"
                  >
                    {record.name}
                  </Typography.Link>
                );
              },
            },
          ]}
          dataSource={entityFiles}
        />
      )}

      {entityDetails && (
        <Form
          form={approveRejectForm}
          initialValues={{
            comments: entityDetails.comments,
            rating: entityDetails.rating,
          }}
        >
          <div
            className="col"
            style={{
              marginBottom: 16,
            }}
          >
            <Title level={5}>Overall comments</Title>
            <Form.Item name="comments">
              <TextArea rows={6} />
            </Form.Item>
          </div>
          <div style={{ width: "200px" }}>
            <Text style={{ margin: "0 16px 0 0" }}>
              <b>Rating</b>
            </Text>

            <Form.Item name="rating">
              <Select placeholder="Rating">
                <Option value="1">1</Option>
                <Option value="2">2</Option>
                <Option value="3">3</Option>
              </Select>
            </Form.Item>
          </div>
          <br />

          {allowWrite && (
            <div>
              <Button
                type="default"
                onClick={async () => {
                  const values = approveRejectForm.getFieldsValue(true);
                  try {
                    await Firebase.updateEntityOnboardingCompanyDetails(
                      entity_id,
                      values
                    );
                    message.success(
                      "Updated entity onboarding company details."
                    );
                  } catch (error) {
                    message.error(error.message);
                  }
                }}
              >
                Update
              </Button>
              <Button
                danger
                style={{ margin: "0 16px 0 0", float: "right" }}
                onClick={async () => {
                  const values = approveRejectForm.getFieldsValue(true);

                  try {
                    await Firebase.updateEntityOnboardingCompanyDetails(
                      entity_id,
                      values
                    );
                    await rejectOnboarding();
                    message.success("Onboarding rejected.");
                  } catch (error) {
                    message.error(error.message);
                  }
                }}
              >
                Reject
              </Button>

              <Button
                disabled={
                  onboardingApprovedClicked || (entity && entity.onboarded)
                }
                style={{
                  background: "#87d068",
                  borderColor: "green",
                  margin: "0 16px 0 0",
                  float: "right",
                }}
                onClick={async () => approveOnboardingClick()}
              >
                Approve onboarding
              </Button>
            </div>
          )}
        </Form>
      )}

      {entity && !entity.onboarded && (
        <Table
          style={{ marginTop: "40px" }}
          title={() => (
            <Title level={2}>
              Integrations
              {allowWrite && (
                <Button
                  style={{ margin: "10px 25px 0px 0px", float: "right" }}
                  onClick={async () => {
                    await Firebase.runBacktester(entity_id);
                  }}
                >
                  Run Backtester calculations
                </Button>
              )}
              {hasXeroIntegration && <ProfitAndLoss entityId={entity_id} />}
            </Title>
          )}
          columns={integrationsColumns}
          dataSource={integrations}
        />
      )}

      <br />
      <br />

      {entity && (
        <EntityUsersAndInvites entityId={entity.id} allowWrite={allowWrite} />
      )}

      <Title level={4}>Cashflows Settings</Title>
      <Form
        form={cashflowsSettingsForm}
        onFinish={async (values) => {
          await Firebase.updateEntityCashflowsSettings(entity_id, values);
          message.success("Updated entity cashflows settings.");
        }}
      >
        <Descriptions bordered>
          <Descriptions.Item label="Cashflows At Risk Netting Period">
            <Form.Item
              name="cashflowsAtRiskNettingPeriod"
              shouldUpdate
              style={{ marginBottom: "0px" }}
            >
              <Select
                options={[
                  { value: "month", label: "Month" },
                  { value: "week", label: "Week" },
                ]}
              />
            </Form.Item>
          </Descriptions.Item>

          <Descriptions.Item>
            <Button type="default" style={{ float: "right" }} htmlType="submit">
              Update
            </Button>
          </Descriptions.Item>
        </Descriptions>
      </Form>
    </div>
  );
};

export default OnboardingDetails;
